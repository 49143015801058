.action {
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
  margin-top: 2em;
  font-size: 20px;
  font-family: "poppins" var(----defaultRegular);
}
.action button {
  padding: 0 1em;
  color: white;
}
.actionleft {
  padding: 0px 2em 0 0em;
}

.actionright {
  border-left: 1px solid;
  padding: 0 0em 0 2em;
}
.main {
  margin: 0px auto;
  max-width: 950px;
  padding-left: 1.0625rem;
  padding-right: 1.0625rem;
  padding-top: 32px;
}

.backgroung {
  height: 24em;
  background-image: linear-gradient(
    to right top,
    #03045e,
    #00267a,
    #004494,
    #0061aa,
    #047ebe
  );
}

.blog1 img {
  width: 100%;
}

.bannerImg {
  padding: 3rem;
}

.blog2 {
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  height: 510px;
  margin-bottom: 30px;
}

.blog3 {
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  height: 510px;
  margin-bottom: 30px;
}

.blog4 {
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  height: 510px;
  margin-bottom: 30px;
}

.blog5 {
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  height: 510px;
  margin-bottom: 30px;
}

.blog6 {
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  height: 520px;
  margin-bottom: 30px;
}

.LandingPageContent {
  align-items: center !important;
  display: flex;
  justify-content: flex-start;
  position: relative;
  top: 60%;
}

.LandingPageContent h6 {
  /* background-color: #e1afaf3b; */
  border-radius: 5px;
  width: 45%;
  font-family: "poppins", sans-serif;
  font-weight: 700 !important;
  padding: 0.5rem;
  font-size: 39px;
  display: flex;
  color: rgb(255, 255, 255) !important;
  text-align: initial;
  position: absolute;
  left: 10%;
}

.icon {
  display: flex;
  width: 100%;
  cursor: pointer;
}

.pre {
  color: #ffff;
  position: absolute;
  right: 15%;
  top: 20%;
  line-height: 40px;
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  border-left: 1px solid;
  font-size: 20px;
  font-family: "poppins" var(----defaultRegular);
}

.pre span {
  align-items: center;
}

.pre p {
  position: relative;
  right: 10rem;
}

.pre button {
  color: white;
}

/* .image-56 {
    position: relative;
    margin-right: 60px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  } */
/* .mainpage{   
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100% !important;
    height: 530px;
   } */
/* .type h1{
    font-family:"poppins" "poppins";
    font-weight: 600;
    font-style: normal;
    font-size: 60px;
    margin: -15rem 0rem 9rem 0rem;
    display: flex;
    color: #ff6502f5;
    justify-content: center;
    /* margin-top: -222px; */
/* width: 85%;
   } */

/* .letter{
    width: 100%;
    height: auto;
    padding-right: 5%;
    padding-left: 5%;
    border-right: 3px none #000;
   } */
.letter {
  font-family: "poppins", sans-serif;
}

.myListEven {
  padding: 25px 0px;
}

.myListOdd {
  display: flex;
  justify-content: end;
}

.letter p {
  font-family: "poppins", sans-serif;
  font-weight: 900;
  line-height: 2rem;
  letter-spacing: 0.4px;
  /* margin-left: 30px; */
  color: #010c54;
  text-align: justify !important;
  font-size: 19px;
  /* text-indent: 5ch; */
}

.letter h4 {
  font-family: "poppins", sans-serif;
  /* margin-left: 30px; */
  font-weight: bold;
}

.letter h5 {
  font-family: "poppins", sans-serif;
  margin-left: 30px;
  font-weight: 500;
  color: #010c54;
}

.letter h3 {
  font-family: "poppins", sans-serif;
  /* margin-left: 30px; */
  font-weight: 900 !important;
  /* margin-right: 30px; */
  color: #ff6300;
  font-size: 25px;
}

.list {
  /* margin-left: 30px; */
  font-weight: 400;
  font-size: 20px;
}

.goback {
  /* margin-left: 30px; */
  display: block;
  float: left;
}

.backimg {
  text-decoration: none;
  font-weight: 700;
  font-size: 20px;
  margin-right: 10px;
  color: black;
}

.image_t {
  width: 20px;
  height: 20px;
  margin-right: 10px !important;
  margin-top: 5px;
}

@media only screen and (min-width: 320px) and (max-width: 766px) {
  .action {
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-family: "poppins" var(----defaultRegular);
    margin-top: 10em;
  }
  .actionleft {
    padding: 0 0em;
  }
  .actionright {
    /* position: absolute;
    right: 12%; */
    border-left: 1px solid;
    padding: 0 0em;
  }
  .LandingPageContent h6 {
    /* background-color: #e1afaf3b; */
    border-radius: 5px;
    width: 80%;
    font-family: "poppins", sans-serif;
    font-weight: 700 !important;
    padding: 0.5rem;
    font-size: 27px;
    display: flex;
    color: rgb(255, 255, 255) !important;
    text-align: center;
    position: absolute;
    left: 10%;
    top: 5%;
  }

  .LandingPageContent {
    align-items: center !important;
    display: flex;
    justify-content: flex-start;
    position: relative;
    top: 25%;
  }

  .pre {
    color: #ffff;
    margin-top: 12em;
    position: absolute;
    right: 24%;
    line-height: 40px;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    border-left: 1px solid;
    font-size: 17px;
    font-family: "poppins" var(----defaultRegular);
  }

  .pre p {
    position: relative;
    color: #ffff;
    right: 8rem;
  }

  .bannerImg {
    padding: 0rem;
    margin: 0.7rem 0;
  }

  .blog1 img {
    width: 100%;
    height: 200px;
  }

  .letter h3 {
    font-family: "poppins", sans-serif;
    font-weight: 900 !important;
    color: #ff6300;
    font-size: 23px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .action {
    align-items: center;
    display: flex;
    justify-content: end;
    color: white;
    font-size: 20px;
    font-family: "poppins" var(----defaultRegular);
    margin-top: 4em;
  }
  .LandingPageContent h6 {
    /* background-color: #e1afaf3b; */
    border-radius: 5px;
    width: 35%;
    font-family: "poppins", sans-serif;
    font-weight: 700 !important;
    padding: 0.5rem;
    font-size: 29px;
    display: flex;
    color: rgb(255, 255, 255) !important;
    text-align: initial;
    position: absolute;
    left: 10%;
    margin-top: 4%;
  }

  .LandingPageContent {
    align-items: center !important;
    display: flex;
    justify-content: flex-start;
    position: relative;
    top: 39%;
  }

  .pre {
    margin-top: 8%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .LandingPageContent h6 {
    /* background-color: #e1afaf3b; */
    border-radius: 5px;
    width: 35%;
    font-family: "poppins", sans-serif;
    font-weight: 700 !important;
    padding: 0.5rem;
    font-size: 29px;
    display: flex;
    color: rgb(255, 255, 255) !important;
    text-align: initial;
    position: absolute;
    left: 10%;
  }

  /* .LandingPageContent h6 {
  margin-top: 0%;
} */
  .pre {
    margin-top: 0%;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
/* body {
    font-family:"poppins" 'Helvetica Neue', 'Helvetica', Arial, "poppins";
 
} */

.head {
  background-color: #f5f5f5;
}
#wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 80em;
  margin: 2rem 0;
}
#container {
  /* padding: 1em; */
  width: 100%;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* justify-content: space-evenly; */
  margin: auto;
}
ol.static-org-chart,
ol.static-org-chart ol,
ol.static-org-chart li,
ol.static-org-chart li > div {
  position: relative;
}
ol.static-org-chart,
ol.static-org-chart ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
ol.static-org-chart {
  text-align: center;
}
ol.static-org-chart ol {
  padding-top: 1em;
  letter-spacing: 0.04em;
}
ol.static-org-chart ol:before,
ol.static-org-chart ol:after,
ol.static-org-chart li:before,
ol.static-org-chart li:after,
ol.static-org-chart > li > div:before,
ol.static-org-chart > li > div:after {
  background-color: #000000;
  content: "";
  position: absolute;
}
ol.static-org-chart ol > li {
  padding: 1em 0 0 1em;
  /* flex-grow: 0.27; */
}
ol.static-org-chart > li ol:before {
  height: 1em;
  left: 50%;
  top: 0;
  width: 3px;
}
ol.static-org-chart > li ol:after {
  height: 2px;
  left: 3px;
  top: 1em;
  width: 50%;
}
ol.static-org-chart > li ol > li:not(:last-of-type):before {
  height: 2px;
  left: 0;
  top: 2em;
  width: 1em;
}
ol.static-org-chart > li ol > li:not(:last-of-type):after {
  height: 105%;
  left: 0;
  top: 0;
  width: 3px;
}
ol.static-org-chart > li ol > li:last-of-type:before {
  height: 3px;
  left: 0;
  top: 2em;
  width: 1em;
}
ol.static-org-chart > li ol > li:last-of-type:after {
  height: 2em;
  left: 0;
  top: 0;
  width: 3px;
}
/*** PRIMARY ***/
ol.static-org-chart > li > div {
  margin-left: 1em;
}
ol.static-org-chart > li > div:before {
  bottom: 2em;
  height: 3px;
  left: -1em;
  width: 1em;
}
ol.static-org-chart > li > div:first-of-type:after {
  bottom: 0;
  height: 2em;
  left: -1em;
  width: 3px;
}
ol.static-org-chart > li > div + div {
  margin-top: 1em;
}
ol.static-org-chart > li > div + div:after {
  height: calc(100% + 1em);
  right: -1em;
  top: -1em;
  width: 3px;
}
/*** SECONDARY ***/
ol.static-org-chart > li > ol:before {
  left: 0;
  right: inherit;
}
ol.static-org-chart > li > ol:after {
  left: 0;
  width: 100%;
}
ol.static-org-chart > li #first:after {
  width: 0%;
}
ol.static-org-chart #second:after {
  width: 0%;
}
ol.static-org-chart #second:before {
  height: 6em !important;
  left: 0 !important;
  top: -5em !important;
}
ol.static-org-chart #first > li > div {
  height: 80px;
  /* width: 250px; */
  background-color: #f5f5f5;
  color: #010c54;
}
ol.static-org-chart #first > li > div > div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 75em) {
  ol.static-org-chart #second:before {
    height: 7em !important;
    left: 50% !important;
    top: -6em !important;
    width: 3px !important;
  }
  ol.static-org-chart {
    margin-left: -1em;
    margin-right: -1em;
  }
  /* PRIMARY */
  ol.static-org-chart > li > div {
    display: inline-block;
    float: none;
    margin: 0 1em 1em 1em;
    vertical-align: bottom;
  }
  ol.static-org-chart > li > div:first-of-type:nth-last-of-type(2),
  ol.static-org-chart > li > div:first-of-type:nth-last-of-type(2) ~ div {
    width: calc((100% / 2) - 2em - 4px);
  }
  ol.static-org-chart > li > div:first-of-type:nth-last-of-type(3),
  ol.static-org-chart > li > div:first-of-type:nth-last-of-type(3) ~ div {
    width: calc((100% / 3) - 2em - 4px);
  }
  ol.static-org-chart > li > div:first-of-type:nth-last-of-type(4),
  ol.static-org-chart > li > div:first-of-type:nth-last-of-type(4) ~ div {
    width: calc((100% / 4) - 2em - 4px);
  }
  ol.static-org-chart > li > div:first-of-type:nth-last-of-type(5),
  ol.static-org-chart > li > div:first-of-type:nth-last-of-type(5) ~ div {
    width: calc((100% / 5) - 2em - 4px);
  }
  ol.static-org-chart > li > div:before,
  ol.static-org-chart > li > div:after {
    bottom: -1em !important;
    top: inherit !important;
  }
  ol.static-org-chart > li > div:before {
    height: 1em !important;
    left: 50% !important;
    width: 3px !important;
  }
  ol.static-org-chart > li > div:only-of-type:after {
    display: none;
  }
  ol.static-org-chart > li > div:first-of-type:not(:only-of-type):after,
  ol.static-org-chart > li > div:last-of-type:not(:only-of-type):after {
    bottom: -1em;
    height: 3px;
    width: calc(50% + 1em + 3px);
  }
  ol.static-org-chart > li > div:first-of-type:not(:only-of-type):after {
    left: calc(50% + 3px);
  }
  ol.static-org-chart > li > div:last-of-type:not(:only-of-type):after {
    left: calc(-1em - 3px);
  }
  ol.static-org-chart > li > div + div:not(:last-of-type):after {
    height: 3px;
    left: -2em;
    width: calc(100% + 4em);
  }
  /* SECONDARY */
  ol.static-org-chart > li > ol {
    display: flex;
    flex-wrap: nowrap;
  }
  ol.static-org-chart > li > ol:before,
  ol.static-org-chart > li > ol > li:before {
    height: 1em !important;
    left: 50% !important;
    top: 0 !important;
    width: 3px !important;
  }
  ol.static-org-chart > li > ol:after {
    display: none;
  }
  ol.static-org-chart > li > ol > li {
    flex-grow: 0.25;
    /* padding-left: em; */
    padding-right: 2em;
    padding-top: 1em;
  }
  ol.static-org-chart > li > ol > li:only-of-type {
    padding-top: 0;
  }
  ol.static-org-chart > li > ol > li:only-of-type:before,
  ol.static-org-chart > li > ol > li:only-of-type:after {
    display: none;
  }
  ol.static-org-chart > li > ol > li:first-of-type:not(:only-of-type):after,
  ol.static-org-chart > li > ol > li:last-of-type:not(:only-of-type):after {
    height: 2px;
    top: 0;
    width: 50%;
  }
  ol.static-org-chart > li > ol > li:first-of-type:not(:only-of-type):after {
    left: 50%;
  }
  ol.static-org-chart > li > ol > li:last-of-type:not(:only-of-type):after {
    left: 0;
  }
  ol.static-org-chart > li > ol > li + li:not(:last-of-type):after {
    height: 2px;
    left: 0;
    top: 0;
    width: 100%;
  }
  ol.static-org-chart #first > li > div {
    height: 80px;
    font-family: "poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    background-color: #f5f5f5;
    color: #010c54;
  }
  /* ol.static-org-chart #first > li > div:hover {
    background-color: #010C54;
    color: #fff;
    cursor: pointer;

   } */
  ol.static-org-chart #first > li > div > div {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
  }
  /* ol.static-org-chart #second > li > div:hover {
    background-color: #010C54;
    color: #fff;
    cursor: pointer;
   } */
}
ol.static-org-chart .staff_card {
  /* width: 358px !important; */
  min-width: 250px !important;
}

ol.static-org-chart .staff_title {
  height: 80px;
  /* width: 170px; */
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
  background-color: #f5f5f5;
  color: #010c54;
  align-items: center;
  display: flex;
  justify-content: center;
}
ol.static-org-chart #first > li > div > div {
  font-family: "poppins", sans-serif;
}

.ol.static-org-chart .staff_title:hover {
  background-color: blue;
  color: #fff;
}
ol.static-org-chart .staff_info {
  height: 80px;
  padding: 5px;
}
ol.static-org-chart .staff_image img {
  border-radius: 100px;
  width: 70px;
}
ol.static-org-chart .staff_image {
  float: left;
  width: 30%;
}
ol.static-org-chart .staff_name_container {
  padding-top: 15px;
  float: right;
  width: 60%;
}
ol.static-org-chart .staff_name_label {
  color: #000053;
  font-size: 16px;
  text-align: left;
}
ol.static-org-chart .staff_name {
  font-size: 14px;
  text-align: left;
}
ol.static-org-chart #second > li > div:not(:first-child) {
  height: 60px;
  width: 250px;
  background-color: #ececec;
  color: #000053;
  font-size: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 0.2rem;
}
ol.static-org-chart #second > li > div:not(:first-child):hover {
  background-color: #010c54;
  color: #fff;
  cursor: pointer;
}

ol.static-org-chart #second > li > div:not(:first-child) > div {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
ol.static-org-chart #second > li > div:not(:first-child):hover {
  background-color: #b3ff66;
  box-shadow: none;
}
ol.static-org-chart .staff_card + div {
  margin-top: 10px;
}

.SoftwareDevlopmentMainHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin: 2rem 0;
}

.SoftwareDevlopmentMainHeader span {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 45px;
  line-height: 24px;
  color: #ff6300;
  padding: 0.5rem;
  margin: 2rem 0;
  letter-spacing: 0.02em;
}

.SoftwareDevlopmentMainhove {
  /* background-color: #089be2; */
  height: 6px;
  width: 70px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.SoftwareDevlopmentContent {
  font-family: "poppins", sans-serif;
  font-weight: 900;
  font-size: 28px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #010c54;
  letter-spacing: 0.02em;
  /* margin-top: 1rem; */
}
.digital {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #010c54;
  margin: 1rem 0;
  letter-spacing: 0.02em;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  ol.static-org-chart #first > li > div {
    height: 80px;
    font-family: "poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    background-color: #f5f5f5;
    color: #010c54;
  }
  ol.static-org-chart .staff_card {
    /* width: 0px !important; */
    min-width: 250px !important;
  }
  .SoftwareDevlopmentMainHeader {
    padding: 0rem;
  }
  .SoftwareDevlopmentMainHeader span {
    font-family: "poppins", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;

    padding: 0.5rem;
  }
  ol.static-org-chart #first > li > div > div {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
  }
  ol.static-org-chart #second > li > div > div {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .SoftwareDevlopmentMainHeader span {
    font-size: 35px;
    line-height: 30px;
  }
  ol.static-org-chart #first > li > div > div {
    font-size: 22px;
    width: 100%;
    font-weight: 600;
  }
}

.LandingPageContent p {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 78px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  justify-content: center;
}

.LandingPageContent h4 {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 58px;
  text-align: center;
  color: #ffffff;
}

.LandingPageContent h6 {
  z-index: 12;
  font-family: "poppins", sans-serif;
  text-transform: uppercase;
  height: auto;
  width: auto;
  color: rgb(255, 255, 255);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: center;
  line-height: 64px;
  letter-spacing: 0px;
  font-weight: 600;
  font-size: 50px;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
  top: 0;
  right: 0;
  left: 0;
  /* font-family:"poppins" "Barlow Condensed";
  font-style: normal;
  font-weight: 300;
  font-size: 70px;
  line-height: 58px;
  text-align: center;
  line-height: 64px;
  letter-spacing: 0px;
  font-weight: 600;
  font-size: 58px;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;

}



.MainHeader1 {
  position: absolute;
  /* background: transparent; */
  /* z-index: 1002; */
}

.landimage2 {
  background-image: url(../../Images/img_career.png);
}

.backgroung {
  /* background: #202641; */
  background-image: linear-gradient(
    to left,
    #130084,
    #090f79,
    #08176e,
    #0e1c61,
    #151f54,
    #282051,
    #34224d,
    #3c254a,
    #54254b,
    #692547,
    #7b283d,
    #883131
  );
  /* background-image: linear-gradient(to right, #031137, #0f214e, #1d3165, #2a437e, #365598, #365598, #365598, #365598, #2a437e, #1d3165, #0f214e, #031137); */
  height: 80px;
}

.landimage {
  background-image: url(../../Images/About_landing.png);
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  height: 700px;
}

.MainheaderScroll {
  display: none;
}

.MainHeaderNavbar {
  background: linear-gradient(
    90deg,
    rgb(1, 12, 84) 0.63%,
    #089be2 53.76%,
    rgba(4, 46, 118, 0.973) 100%
  );
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.MainheaderLogo {
  display: flex;
  justify-content: space-around;
}

.MainheaderLogo1 {
  width: 394px;
  height: 96px;
  left: 130px;
  top: 41px;
}

.NavbarLogo {
  width: 200px;
  height: 50px;
}

/* .Contact_Us {
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
    border-radius: 10px;
    margin-right: 1rem;
    width: 137px;
    font-family:"poppins" "poppins";
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    border: none;
} */
.ContactusMain {
  align-items: center;
  display: flex;
  justify-content: end;
}

.Hire_Us {
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  border-radius: 10px;
  margin-right: 1rem;
  width: 137px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.landingMainheader {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LandingPageContent {
  margin-top: 25%;
}

.LandingPageContent p {
  width: 100%;
  height: 225px;
  /* font-family:"poppins" "Barlow Condensed" !important; */
  font-style: normal;
  font-weight: 200 !important;
  font-size: 45px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 0.3rem;
  margin: 10rem 0rem 0rem 0rem !important;
}

.LandingPageContent1 {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 49px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 2rem;
  line-height: 20px;
}

.Learn_more {
  width: 154px;
  top: 574px;
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  border-radius: 10px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.Learn_moreMain {
  padding: 1.5rem;
}

.MainDevelopCard {
  display: flex;
  flex-direction: row;
  /* / justify-content: center; /
        / align-items: baseline; / */
}

.Developmentcard {
  width: 24.5%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.2rem;
  cursor: pointer;
  background-color: #fdfdfd;
}

.Developmentcard:hover {
  width: 24.5%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.2rem;
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  cursor: pointer;
}

.Developmentcard:hover > pre {
  color: #ffffff;
}

.Developmentcard:hover > p {
  color: #ffffff;
}

.cardIcon {
  width: 80px;
  height: 80px;
}

.Developmentcard pre {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: bolder;
  font-size: 16px;
  text-align: center;
  white-space: pre-wrap;
  word-break: break-word;
  color: #042e76;
  margin: 1rem 0rem 0rem 0rem;
  height: 50px;
}

.Developmentcard p {
  font-size: 12px;
  height: 70px;
  margin: 0px !important;
  font-family: "poppins", sans-serif;
  font-weight: 900;
  height: 120px;
}

.SoftwareDevlopmentMainHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.SoftwareDevlopmentMainHeader span {
  font-family: "poppins" "poppins" !important;
  /* font-style: normal; */
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  color: #010c54;
  padding: 0.5rem;
}

.SoftwareDevlopmentMainhove {
  background-color: #089be2;
  height: 6px;
  width: 70px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.SoftwareDevlopmentContent {
  width: 616px;
  height: 72px;
  left: calc(50% - 616px / 2);
  top: calc(50% - 72px / 2 - 1234px);
  font-family: "poppins", sans-serif;
  font-weight: 700;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #010c54;
}

/*--------------------*/
.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #fff;
  background-color: #0e1257;
}

.l_footer {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.footerSub {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem;
}

.MainheaderFooterLogo {
  width: 250px;
}

.Location {
  margin-right: 1rem;
}

h2 {
  font-weight: 400;
  font-size: 15px;
}

.h_box a {
  color: #999;
}

.Menu {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
}

.Services {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 41px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
}

.FooterLocationContent {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
}

.h_box {
  column-count: 2;
  column-gap: 1.25em;
}

.b_footer {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.b_footerImg {
  display: inline-block;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
}

.b_footerImg img {
  margin-right: 1rem;
}

.b_footer p {
  margin: 0px !important;
}

.b_footerTxt {
  display: inline-block;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  color: rgba(255, 255, 255, 0.9);
}

.l_footer p {
  padding-right: 20%;
  color: #999;
}

.HeaderMenu {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #f27121;
}

.MenuMain {
  display: flex;
  flex-direction: column;
  width: 16%;
}

.FooterLocationContent span {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  margin-top: 1rem;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
}

.FooterPhone {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
}

.FooterPhone span {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
}

.FooterMain {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
}

.FooterMain span {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .LandingPageContent h6 {
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 6em 0em;
  }

  .LandingPageContent h4 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 20px;
    line-height: 38px;
    position: absolute;
    top: 45%;
  }

  .MainDevelopCard {
    display: flex;
    flex-direction: column;
  }

  .FooterPhone span {
    padding: 0rem;
  }

  .FooterMain span {
    padding: 0rem;
  }

  .Phone {
    margin-right: 1rem;
  }

  .footerSub {
    display: flex;
    flex-direction: column;
  }

  .l_footer {
    width: 100%;
  }

  .LandingPageContent p {
    font-size: 50px !important;
    margin: 2rem 0rem 0rem 0rem !important;
  }

  .LandingPageContent {
    margin-top: 40%;
  }

  .MenuMain {
    width: 100%;
    align-items: center;
    margin-top: 1rem;
  }

  .Developmentcard {
    width: 100%;
  }

  .Developmentcard:hover {
    width: 100%;
  }

  .SoftwareDevlopmentMainHeader span {
    display: flex;
    text-align: center;
  }

  .LandingPageContent .ContactusMain {
    display: none;
  }

  .landingMainheader {
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .MainheaderFooterLogo {
    width: 220px;
    height: 65px;
    display: flex;
    margin: auto;
  }

  .MainHeaderNavbar {
    background: linear-gradient(
      90deg,
      rgb(1, 12, 84) 0.63%,
      #089be2 53.76%,
      rgba(4, 46, 118, 0.973) 100%
    );
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .landimage {
    background-image: url(../../Images/About_landing.png);
    background-repeat: no-repeat;
    width: 100%;
    height: 525px;
    background-size: cover;
  }

  /* .footer {
        padding: .5rem !important;
      } */
  .FooterLocationContent {
    display: flex;
    flex-direction: column;
  }

  .FooterLocationContent span {
    padding: 0rem;
  }

  .Location {
    margin-right: 0rem;
  }

  .b_footer {
    flex-direction: column;
  }

  .b_footer p {
    margin: 0px !important;
    font-size: 12px;
  }

  .Contact_UsButtonHeader {
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .Contact_UsButton {
    width: 65% !important;
  }
}

.Contact_UsButtonHeader {
  padding-top: 1rem;
  display: flex;
}

.UpSquaredScroll {
  width: 20%;
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  border-radius: 7px;
  margin-right: 1rem;
  border: none;
}

/* .MainIcon{
      padding-top: .5rem;
    } */
.Contact_UsButton {
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  border-radius: 20px;
  /* width: 87%; */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  border: navajowhite;
  /* color: #fff; */
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 20px; */
  text-align: center;
  color: #ffffff;
  padding: 0.5rem;
}

.Contact_UsTwo {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .LandingPageContent {
    margin-top: 35%;
  }
  .Contact_UsButton {
    background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
    border-radius: 20px;
    /* width: 87%; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    border: navajowhite;
    /* color: #fff; */
    font-family: "poppins", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 9px;
    /* line-height: 20px; */
    text-align: center;
    color: #ffffff;
    padding: 0.5rem;
    height: 100%;
  }

  .UpSquaredScroll {
    height: 86%;
    width: 40%;
    background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
    border-radius: 7px;
    margin-right: 1rem;
    border: none;
  }

  .LandingPageContent h6 {
    font-family: "poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px !important;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
  }
  .LandingPageContent p {
    margin: 5rem 0rem 0rem 0rem !important;
  }
}

@media only screen and (min-width: 768px) {
  .MainheaderFooterLogo {
    width: 210px;
  }

  .LandingPageContent p {
    font-size: 36px;
  }

  .LandingPageContent1 {
    padding: 0.5rem;
  }
}

@media only screen and (min-width: 761px) and (max-width: 1000px) {
  .backgroung {
    height: 60px !important;
  }
}

@media screen and (device-width: 1024px) {
  .backgroung {
    height: 55px;
  }
}

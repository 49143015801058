.MoreInfoHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 2rem 0;
}
.MoreInfoHeader1 {
  /* background: linear-gradient(
    90deg,
    rgba(1, 12, 84, 0.77) 0.63%,
    #089be2 53.76%,
    rgba(4, 46, 118, 0.87) 100%
  ); */
  /* background-image: linear-gradient(to left top, #001778, #002881, #003689, #004490, #005195, #005896, #005f96, #006595, #006791, #00698c, #006b87, #006c81); */
  background-image: linear-gradient(
    to right top,
    #03045e,
    #00267a,
    #004494,
    #0061aa,
    #047ebe
  );
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 70%;
  padding: 1.5rem;
  align-items: center;
}
.MVPContent p {
  letter-spacing: 0.03em;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.More_info {
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  border-radius: 10px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  cursor: auto !important;
  /* line-height: 20px; */
  text-align: center;
  color: #ffffff;
  border: none;
  padding: 0.5rem;
}
.More_info:hover {
  /* background: #003a57 !important; */
  background: linear-gradient(
    90deg,
    rgba(1, 12, 84, 0.77) 0.63%,
    #089be2 53.76%,
    rgba(4, 46, 118, 0.87) 100%
  );
}
.MoreInfoHeader2 {
  width: 39%;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .MoreInfoHeader1 {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    text-align: center;
  }
  .MoreInfoHeader {
    text-align: center;
    display: flex;
    align-items: center;
    padding: 1rem;
    text-align: center;
  }

  .MoreInfoHeader2 {
    width: 75%;
    margin-bottom: 1rem;
  }
  .MVPContent p {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .MVPContent p {
    font-size: 18px;
    font-weight: 600 !important;
  }
}

.SoftwareDevlopmentMain {
  background-color: #f5f5f5;
  padding: 3rem;
}
.SoftwareDevlopmentMainHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* padding: 2rem; */
}

.SoftwareDevlopmentMainHeader span {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 900 !important;
  font-size: 39px;
  line-height: 24px;
  color: #ff6300;
  padding: 0.5rem;
  letter-spacing: 0.02em;
}

.SoftwareDevlopmentMainhove {
  /* background-color: #089be2; */
  height: 6px;
  width: 70px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.SoftwareDevlopmentContent {
  height: 100px;
  /* width: 80%; */
  font-family: "poppins", sans-serif;
  font-weight: 600 !important;
  font-size: 29px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #010c54;
  letter-spacing: 0.02em;
}
.PersonalgrowthHeader {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition-property: width;
  transition-duration: 2s;
}

.PersonalgrowthHeader img {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row-reverse;
}

.MindmapHeader {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.MindmapHeader img {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row-reverse;
}
.PersonalgrowthHeader span {
  width: 294px;
  height: 70px !important;
  font-family: "poppins" "poppins" !important;
  font-style: normal;
  font-size: 20px;
  font-weight: 700 !important;
  display: flex;
  align-items: center;
  text-align: center;
  color: #010c54;
  margin-top: 1rem;
}

.MindmapHeader span {
  /* width: 294px;
  font-size: 20px;
  font-family:"poppins" "inherit" !important;
  font-style: normal;
  font-weight: 700 !important;
  color: #010c54;
  margin-top: 1rem;
  text-align: center; */
  width: 294px;
  height: 70px !important;
  font-family: "poppins" "poppins" !important;
  font-style: normal;
  font-size: 20px;
  font-weight: 700 !important;
  display: flex;
  align-items: center;
  text-align: center;
  color: #010c54;
  margin-top: 1rem;
}

.GlobalHeader1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.GlobalHeader1 img {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row-reverse;
}

.GlobalHeader1 span {
  width: 294px;
  height: 70px !important;
  font-family: "poppins" "poppins" !important;
  font-style: normal;
  font-size: 20px;
  font-weight: 700 !important;
  display: flex;
  align-items: center;
  text-align: center;
  color: #010c54;
  margin-top: 2rem !important;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .SoftwareDevlopmentContent {
    width: 100%;
    height: 20px;
    color: #010c54;
    margin: 5rem !important;
  }
  .SoftwareDevlopmentMainHeader span {
    text-align: center;
    font-size: 30px;
    line-height: 35px;
  }
  .SoftwareDevlopmentMainHeader {
    padding: 1rem;
  }
  .PersonalgrowthHeader img {
    width: 30%;
    margin: 2rem;
  }
  .PersonalgrowthHeader span {
    width: 294px;
    height: 52px;
    font-family: "poppins", sans-serif;
    font-style: normal;
    font-size: 20px;
    font-weight: 900;
    display: flex;
    align-items: center;
    text-align: center;
    color: #010c54;
    margin-top: 0rem !important;
    letter-spacing: 0.02em;
  }
  .GlobalHeader1 span {
    width: 294px;
    /* height: 70px !important; */
    font-family: "poppins" "poppins" !important;
    font-style: normal;
    font-size: 20px;
    font-weight: 700 !important;
    display: flex;
    align-items: center;
    text-align: center;
    color: #010c54;
    margin-top: 0rem !important;
  }
  .SoftwareDevlopmentContent {
    height: 0px;
    color: #010c54;
    margin-top: 5rem !important;
    font-size: 25px !important;
  }
  .SoftwareDevlopmentMainHeader span {
    text-align: center;
  }
  .SoftwareDevlopmentMainHeader {
    padding: 1rem;
  }
  /* .PersonalgrowthHeader img {
    width: 30%;
    margin: 3rem;
  } */
  .MindmapHeader img {
    width: 30%;
    margin: 3rem;
  }
  .GlobalHeader1 img {
    width: 50% !important;
    margin: 3rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .GlobalHeader1 img {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: row-reverse;
  }
  .SoftwareDevlopmentMainHeader {
    font-size: 35px;
  }
  .SoftwareDevlopmentContent {
    font-size: 20px;
  }
  .SoftwareDevlopmentMainHeader span {
    font-size: 35px !important;
    line-height: 37px !important;
  }
}
.MindmapHeader img {
  /* width: 42%; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row-reverse;
}
.PersonalgrowthHeader span {
  width: 294px;
  height: 52px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: 900;
  display: flex;
  align-items: center;
  text-align: center;
  color: #010c54;
  margin-top: 1rem;
  letter-spacing: 0.02em;
}
.MindmapHeader span {
  width: 294px;
  height: 80px;
  font-size: 20px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #010c54;
  margin-top: 1rem;
  text-align: center;
}

.GlobalHeader1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
/* .GlobalHeader1 img { */
/* width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row-reverse; */
/* } */

.GlobalHeader1 span {
  width: 294px;
  height: 52px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
  display: flex;
  align-items: center;
  text-align: center;
  color: #010c54;
  font-size: 20px;
  margin-top: 1.5rem;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
}

.perks {
  background: #f5f5f5;
  padding: 4em;
}

.Applicationcontent {
  padding: 4em;
}

#linkspara {
  color: #ff6300;
  font-family: "poppins", sans-serif;
}

.samp {
  display: flex;
  justify-content: center;
}

.samp img {
  width: auto;
  height: 120px;
}

.backgroung {
  background-image: linear-gradient(
    to left,
    #130084,
    #090f79,
    #08176e,
    #0e1c61,
    #151f54,
    #282051,
    #34224d,
    #3c254a,
    #54254b,
    #692547,
    #7b283d,
    #883131
  );
  height: 80px;
}

.landimage {
  background-image: url(../../../Images/LandingPage.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 750px;
  background-size: cover;
}

.Employeeimg img {
  width: 90%;
  height: 350px;
}

#carouselimg {
  width: 100%;
  height: 450px;
}

.CareerPerks {
  font-size: 50px;
  font-family: "poppins", sans-serif;
  font-weight: 800;
  color: #ff6300;
  display: flex !important;
  letter-spacing: 0.02em;
}

.MainHeaderNavbar {
  background: linear-gradient(
    90deg,
    rgb(1, 12, 84) 0.63%,
    #089be2 53.76%,
    rgba(4, 46, 118, 0.973) 100%
  );
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.LandingPageContent {
  /* font-family:"poppins" "Barlow Condensed"; */
  font-weight: 300;
  font-size: 64px;
  line-height: 78px;
  /* text-shadow: 8px 4px 9px black; */
  margin: 5rem 0rem 0rem 0rem;
  text-align: center;
}

.LandingPageContent2 {
  text-transform: uppercase;
  font-family: "poppins", sans-serif;
  font-weight: 900;
  font-size: 45px;
  /* 
    text-shadow: 8px 4px 9px black; */
  margin: 17rem 0rem 0rem 0rem;
  text-align: center;
  color: white;
}

.LandingPageContent1 {
  font-family: "poppins", sans-serif;
  font-weight: 900;
  font-size: 35px;
  /* text-shadow: 8px 4px 9px black; */
  line-height: 78px;
  text-align: center;
  color: white;
}

.LandingPageContent p {
  font-family: "poppins" "Barlow Condensed";
  font-weight: 200;
  font-size: 60px;
  margin: 12rem 0rem 0rem 0rem;
  display: flex;
  align-items: center;
  color: antiquewhite;
  justify-content: center;
}

.landingMainheader {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MainheaderLogo {
  display: flex;
  justify-content: space-around;
}

.MainheaderLogo1 {
  width: 394px;
  height: 96px;
  left: 130px;
  top: 41px;
}

.MainText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#CareerMainheader {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.text1 {
  font-family: "poppins", sans-serif;
  line-height: 1.6em;
  color: #010c54;
  font-weight: 700;
  font-style: normal;
  text-transform: none;
  margin-bottom: 2rem;
}

.text {
  font-family: "poppins", sans-serif;
  display: block;

  font-weight: 700;
  font-size: 1.23em;
  color: #010c54;
  line-height: 1.5em;
  letter-spacing: 0.03em;
}

.btn {
  color: #fff;
  border-radius: 30px;
  font-size: 18px;
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  color: #fff;
  border: none;
  width: 25%;
  height: 40px;
  font-family: "poppins" 600;
}

.btn1 {
  color: #fff;
  border-radius: 30px;
  font-size: 18px;
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  color: #fff;
  border: none;
  margin-left: 5rem;
  width: 25%;
  height: 40px;
  font-family: "poppins" 600;
}

.btn3 {
  color: #fff;
  border-radius: 30px;
  font-size: 18px;
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  color: #fff;
  border: none;
  margin-left: 5rem;
  width: 25%;
  height: 40px;
  font-family: "poppins" 600;
}

.btn5 {
  color: #fff;
  border-radius: 30px;
  font-size: 18px;
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  color: #fff;
  border: none;
  margin-left: 5rem;
  width: 25%;
  height: 40px;
  font-family: "poppins" 600;
}

.btn6 {
  margin: 1em 0;
  border-radius: 30px;
  font-size: 18px;
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  color: #fff;
  border: none;
  width: 25%;
  height: 40px;
  font-family: "poppins" 800;
}

.btn6:hover {
  background: linear-gradient(
    90deg,
    rgba(1, 12, 84, 0.77) 0.63%,
    #089be2 53.76%,
    rgba(4, 46, 118, 0.87) 100%
  );
  color: #ffffff;
}

.btn:hover {
  background: linear-gradient(
    90deg,
    rgba(1, 12, 84, 0.77) 0.63%,
    #089be2 53.76%,
    rgba(4, 46, 118, 0.87) 100%
  );
  color: #ffffff;
}

.btn1:hover {
  background: linear-gradient(
    90deg,
    rgba(1, 12, 84, 0.77) 0.63%,
    #089be2 53.76%,
    rgba(4, 46, 118, 0.87) 100%
  );
  color: #ffffff;
}

.btn3:hover {
  background: linear-gradient(
    90deg,
    rgba(1, 12, 84, 0.77) 0.63%,
    #089be2 53.76%,
    rgba(4, 46, 118, 0.87) 100%
  );
  color: #ffffff;
}

.btn5:hover {
  background: linear-gradient(
    90deg,
    rgba(1, 12, 84, 0.77) 0.63%,
    #089be2 53.76%,
    rgba(4, 46, 118, 0.87) 100%
  );
  color: #ffffff;
}

.Culture_Content {
  font-family: "poppins", sans-serif;
  font-size: 29px;
  font-weight: 900;
  color: #010c54;
  letter-spacing: 0.02em;
}

.Culture_Content p {
  font-family: "poppins", sans-serif;
  font-size: 29px;
  font-weight: 900;
  color: #ff6300;
  letter-spacing: 0.02em;
}

.Culture_Content span {
  font-family: "poppins", sans-serif;
  font-size: 29px;
  color: #010c54;
  letter-spacing: 0.02em;
}

.Designerscontent h3 {
  font-family: "poppins", sans-serif;
  font-size: 40px;
  color: #ff6300;
  font-weight: 900;
  margin-bottom: 2rem;
  letter-spacing: 0.02em;
}

.Designerscontent1 h3 {
  font-family: "poppins", sans-serif;
  font-size: 40px;
  color: #ff6300;
  font-weight: 900;
  margin-bottom: 2rem;
  letter-spacing: 0.02em;
}

.Designerscontent2 h3 {
  font-size: 40px;
  color: #ff6300;
  font-weight: 900;
  margin-bottom: 2rem;
}

.Designerscontent3 h3 {
  font-family: "poppins", sans-serif;
  font-size: 40px;
  color: #ff6300;
  font-weight: 900;
  margin-bottom: 2rem;
}

.Designerscontent4 h3 {
  font-family: "poppins", sans-serif;
  font-size: 40px;
  color: #ff6300;
  font-weight: 900;
  margin-bottom: 2rem;
  letter-spacing: 0.02em;
}

.Designerscontent5 h3 {
  font-size: 40px;
  color: #ff6300;
  font-weight: 400;
  margin-bottom: 2rem;
}

.Competitivecareer p {
  font-size: 20px;
  font-weight: 700;
  color: #010c54;
  letter-spacing: 0.04em;
}

.Designerscontent p {
  font-family: "poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #010c54;
  margin-bottom: 2rem;
  margin-right: 5rem;
  text-align: justify;
  letter-spacing: 0.02em;
}

.Designerscontent2 p {
  font-size: 20px;
  font-weight: 700;
  color: #010c54;
  margin-bottom: 2rem;
  margin-right: 5rem;
  text-align: justify;
}

.Developmentheader h3 {
  font-family: "poppins" var(--defaultRegular);
  text-align: center;
  font-weight: 900;
  color: #010c54;
}

.Developmentheader {
  margin-bottom: 3rem;
  letter-spacing: 0.03em;
}

.CareerCardImage4 {
  margin-right: 4rem;
}

.Corecontent h3 {
  font-family: "poppins", sans-serif;
  font-size: 47px;
  color: #ff6300;
  margin-bottom: 1rem;
  font-weight: 900;
}

.Corecontent p {
  font-family: "poppins", sans-serif;
  font-size: 23px;
  color: #010c54;
  font-weight: 700;
}

.Corecontent {
  margin-top: 5rem;
  letter-spacing: 0.03em;
}

.careertick {
  font-family: "poppins", sans-serif;
  font-size: 20px;
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
}

.careertickconetent img {
  width: 15px;
}

.careertickconetent {
  font-family: "poppins", sans-serif;
  margin: 1rem;
  color: #ff6300;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: 0.04em;
}

#Development {
  padding: 4rem;
}

#Development1 {
  background: #f3f6f9;
  padding: 4rem;
}

#Development2 {
  padding: 2rem;
}

#Development3 {
  background: #f3f6f9;
  padding: 4rem;
}

#Development4 {
  padding: 4rem;
}

#Development5 {
  background: #f3f6f9;
  padding: 4rem;
}

.CareerCardImage {
  display: flex;
  justify-content: center;
}

.CareerCardImage1 {
  display: flex;
  justify-content: center;
}

.CareerCardImage2 {
  display: flex;
  justify-content: center;
}

.CareerCardImage3 {
  display: flex;
  justify-content: center;
}

.CareerCardImage4 {
  display: flex;
  justify-content: center;
  width: 100%;
}

.CareerCardImage5 {
  display: flex;
  justify-content: center;
}

.CareerCardImage img {
  width: 60%;
}

.CareerCardImage1 img {
  width: 60%;
}

.CareerCardImage2 img {
  width: 60%;
}

.CareerCardImage3 img {
  width: 60%;
}

.CareerCardImage4 img {
  width: 60%;
}

.CareerCardImage5 img {
  width: 60%;
}

.Designerscontent3 p {
  font-family: "poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #010c54;
  margin-left: 5rem;
  margin-bottom: 3rem;
  text-align: justify;
}

.Designerscontent4 p {
  font-family: "poppins", sans-serif;
  font-size: 20px;
  font-weight: 700 !important;
  color: #010c54;
  margin-bottom: 3rem;
  margin-right: 5rem;
  text-align: justify;
  letter-spacing: 0.02em;
}

.Designerscontent1 p {
  font-family: "poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #010c54;
  margin-bottom: 3rem;
  margin-left: 5rem;
  text-align: justify;
  letter-spacing: 0.02em;
}

.Designerscontent3 h3 {
  font-size: 40px;
  color: #ff6300;
  font-weight: 900;
  margin-left: 5rem;
  margin-bottom: 2rem;
}

.Designerscontent1 h3 {
  font-size: 40px;
  color: #ff6300;
  font-weight: 900;
  margin-left: 5rem;
  margin-bottom: 2rem;
  letter-spacing: 0.02em;
}

.Designerscontent5 p {
  font-family: "poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #010c54;
  margin-left: 5rem;
  margin-bottom: 3rem;
  text-align: justify;
  letter-spacing: 0.02em;
}

.Designerscontent5 h3 {
  font-family: "poppins", sans-serif;
  font-size: 40px;
  color: #ff6300;
  font-weight: 900;
  margin-left: 5rem;
  margin-bottom: 2rem;
  letter-spacing: 0.02em;
}

/* #card {
    background: #f3f6f9;
  } */

.Applicationcontent h3 {
  font-family: "poppins", sans-serif;
  font-size: 40px;
  text-align: center;
  color: #ff6300;
  padding: 20px;
  margin-bottom: 2rem;
  font-weight: 900;
}

.Applicationcontent p {
  font-family: "poppins", sans-serif;
  font-size: 20px;
  text-align: center;
  font-weight: 700;
}

#ImageHead {
  background: #f3f6f9;
  margin-bottom: 5rem;
  padding: 3rem;
}

/* 
  .footerimg1 {
    width: 80%;
    padding: 3px;
  }
  .footerimg2 {
    width: 100%;
    height: 100%;
    padding: 3px;
  } */

.footerimg4 {
  width: 100%;
  padding: 3px;
}

#col1 {
  padding: 0;
}

/*-----------Media Query Career -----------*/

@media (min-width: 320px) and (max-width: 750px) {
  /* .body{
    padding: 1rem !important;
   } */
  #CareerMainheader {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0 2rem;
  }

  .perks {
    background: #f5f5f5;
    padding: 2em 0em;
  }

  .careertickconetent span {
    font-size: 19px;
    width: 100% !important;
  }

  .Applicationcontent p {
    font-size: 20px !important;
    text-align: start !important;
    padding: 5px !important;
  }

  .Employeeimg img {
    width: 100%;
    margin-bottom: 2rem;
    padding: 10px;
    height: 250px;
    display: flex;
    justify-content: center;
  }

  .Competitivecareerhead {
    padding: 10px;
  }

  #carouselimg {
    width: 100%;
    height: 250px;
  }

  .LandingPageContent2 {
    font-family: "poppins", sans-serif;
    font-weight: 800;
    font-size: 30px;
    line-height: 60px;
    margin: 10rem 0rem 0rem 0rem;
    text-align: center;
  }

  .Culture_Content p {
    font-family: "poppins", sans-serif;
    font-size: 26px;
    font-weight: 900;
    color: #ff6300;
  }

  .Culture_Content span {
    font-family: "poppins", sans-serif;
    font-size: 28px;
    color: #010c54;
  }

  .LandingPageContent {
    font-size: 40px;
    display: flex;
    justify-content: center;
    padding: 0;
    line-height: 60px;
    margin: 2rem 0rem 0rem 0rem;
    font-weight: 600;
    text-align: center;
    /* font-family:"poppins" "Barlow Condensed"; */
  }

  .LandingPageContent1 {
    font-size: 20px;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    font-weight: 500;
    text-align: center;
    line-height: 34px;
    font-family: "poppins", sans-serif;
  }

  .LandingPageContent p {
    width: 100%;
    /* font-family:"poppins" "Barlow Condensed"; */
    font-style: normal;
    font-weight: 200;
    font-size: 50px;
    line-height: 60px;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin: 6rem 0rem 0rem 0rem;
  }

  .Culture_Content {
    font-size: 28px;
    /* text-align: justify; */
    font-weight: 900;
  }

  .Culture_Content1 {
    font-size: 23px;
    color: #010c54;
    text-align: justify;
    font-weight: 900;
  }

  .Corecontent {
    margin-top: 0;
  }

  .Corecontent p {
    font-size: 22px;
    margin: 1rem 1.5rem;
  }

  .Corecontent h3 {
    font-size: 35px;
    color: #ff6300;
    margin-bottom: 1rem;
    text-align: center;
  }

  .text {
    font-size: 20px;
    color: #010c54;
    line-height: 1.5em;
    margin: 20px 0;
    /* text-align: justify; */
  }

  .CareerPerks {
    font-size: 35px;
    font-weight: 900;
    color: #ff6300;
    margin: 0 0.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .careertick {
    font-size: 17px;
    display: flex;
    flex-direction: column;
  }

  .careertickconetent img {
    width: 5%;
    height: 18px;
  }

  .careertickconetent p {
    font-size: 19px;
    display: flex;
  }

  .careertickconetent {
    display: flex;
    font-weight: 700;
  }

  .careertick p {
    margin-left: 0;
  }

  #Development5 {
    margin-top: 2rem;
    background: #f3f6f9;

    padding: 20px;
  }

  .Designerscontent5 h3 {
    font-size: 28px;
    color: #ff6300;

    margin-left: 0;
    margin-bottom: 1rem;
  }

  .Designerscontent5 p {
    font-size: 20px;

    color: #010c54;
    margin: 0;
    margin-bottom: 1rem;
  }

  #Development4 {
    margin-top: 2rem;
    padding: 20px;
  }

  .Designerscontent4 h3 {
    font-size: 28px;
    color: #ff6300;
    margin-left: 0;
    margin-bottom: 1rem;
  }

  .Designerscontent4 p {
    font-size: 20px;
    font-weight: 400;
    color: #010c54;
    margin: 0;
    margin-bottom: 1rem;
  }

  #Development1 {
    margin-top: 2rem;
    background: #f3f6f9;
    padding: 20px;
  }

  .Designerscontent1 h3 {
    font-size: 26px;
    color: #ff6300;
    font-weight: 900;
    margin-left: 0;
    margin-bottom: 1rem;
  }

  .Designerscontent1 p {
    font-size: 20px;

    color: #010c54;
    margin: 0;
    margin-bottom: 1rem;
  }

  #Development {
    margin-top: 2rem;
    padding: 20px;
  }

  .Designerscontent h3 {
    font-size: 28px;
    color: #ff6300;
    margin-left: 0;
    margin-bottom: 1rem;
  }

  .Designerscontent p {
    font-size: 20px;

    color: #010c54;
    margin: 0;
    margin-bottom: 1rem;
  }

  #Development3 {
    margin-top: 2rem;
    background: #f3f6f9;
    padding: 20px;
  }

  .Designerscontent3 h3 {
    font-size: 28px;
    color: #ff6300;

    margin-left: 0;
    margin-bottom: 1rem;
  }

  .Designerscontent3 p {
    font-size: 20px;

    color: #010c54;
    margin: 0;
    margin-bottom: 1rem;
  }

  #Development2 {
    margin-top: 2rem;
    padding: 20px;
  }

  .Designerscontent2 h3 {
    font-size: 28px;
    color: #ff6300;

    margin-left: 0;
    margin-bottom: 1rem;
  }

  .Designerscontent2 p {
    font-size: 20px;

    color: #010c54;
    margin: 0;
    margin-bottom: 1rem;
  }

  .footerimg1 {
    width: 50%;
  }

  .btn {
    width: 40%;
    margin-left: 0;
  }

  .btn1 {
    width: 40%;
    margin-left: 0;
    margin-bottom: 2rem;
  }

  .btn3 {
    width: 40%;
    margin-left: 0;
    margin-bottom: 2rem;
  }

  .btn5 {
    width: 40%;
    margin-left: 0;
    margin-bottom: 2rem;
  }

  .btn6 {
    width: 40%;
  }

  .sys {
    display: flex;
    justify-content: center;
  }

  .CareerCardImage img {
    width: 100%;
    margin-bottom: 2rem;
  }

  .CareerCardImage1 img {
    width: 100%;
    margin-bottom: 2rem;
  }

  .CareerCardImage2 img {
    width: 100%;
    margin-bottom: 2rem;
  }

  .CareerCardImage3 img {
    width: 100%;
    margin-bottom: 2rem;
  }

  .CareerCardImage4 img {
    width: 100%;
    margin-bottom: 2rem;
  }

  .CareerCardImage5 img {
    width: 100%;
    margin-bottom: 2rem;
  }

  .Applicationcontent h3 {
    font-size: 30px;
    text-align: center;
    color: #ff6300;
  }

  .Applicationcontent p {
    font-size: 20px;
    text-align: center !important;
  }

  #linkspara {
    font-size: 18px;
  }
}

@media (min-width: 750px) and (max-width: 1200px) {
  .Corecontent {
    margin-top: 0rem;
    letter-spacing: 0.03em;
  }

  .CareerPerks {
    display: flex;
    justify-content: center;
  }

  .Employeeimg {
    display: flex;
    justify-content: center;
  }

  .Competitivecareerhead {
    padding: 1em 0em;
  }

  .Designerscontent p {
    font-size: 20px;
    text-align: justify;
    display: contents;
  }

  .Designerscontent1 p {
    font-size: 20px;
    text-align: justify;
    display: contents;
  }

  .Designerscontent2 p {
    font-size: 20px;
    text-align: justify;
    display: contents;
  }

  .Designerscontent3 p {
    font-size: 20px;
    text-align: justify;
    display: contents;
  }

  .Designerscontent4 p {
    font-size: 20px;
    text-align: justify;
    display: contents;
  }

  .Designerscontent5 p {
    font-size: 20px;
    text-align: inherit;
    display: contents;
  }

  #gridimage > * {
    flex-shrink: 0;
    width: 50%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0);
    padding-left: calc(var(--bs-gutter-x) * 0);
  }

  .flex-column-reverse #gridimage {
    flex-direction: 0 !important;
    display: -webkit-box !important;
  }

  #techcard > * {
    flex-shrink: 0;
    width: 50%;
  }

  .btn {
    width: 40%;
    margin-left: 0rem;
    margin-top: 2rem;
  }

  .btn1 {
    width: 40%;
    margin-left: 0rem;
    margin-top: 2rem;
  }

  .btn2 {
    width: 40%;
    margin-left: 0rem;
    margin-top: 2rem;
  }

  .btn3 {
    width: 40%;
    margin-left: 0rem;
    margin-top: 2rem;
  }

  .btn5 {
    width: 40%;
    margin-left: 0rem;
    margin-top: 2rem;
  }

  .btn4 {
    width: 40%;
    margin-left: 0rem;
    margin-top: 2rem;
  }

  .btn5 {
    width: 40%;
    margin-left: 0rem;
    margin-top: 2rem;
  }

  .CareerCardImage img {
    width: 100%;
    margin-top: 2rem;
  }

  .CareerCardImage1 img {
    width: 100%;
    margin-top: 2rem;
  }

  .CareerCardImage2 img {
    width: 100%;
    margin-top: 2rem;
  }

  .CareerCardImage3 img {
    width: 100%;
    margin-top: 2rem;
  }

  .CareerCardImage4 img {
    width: 100%;
    margin-top: 2rem;
  }

  .CareerCardImage5 img {
    width: 100%;
    margin-top: 2rem;
  }

  .Designerscontent h3 {
    margin-left: 0;
  }

  .Designerscontent1 h3 {
    margin-left: 0;
  }

  .Designerscontent2 h3 {
    margin-left: 0;
  }

  .Designerscontent3 h3 {
    margin-left: 0;
  }

  .Designerscontent4 h3 {
    margin-left: 0;
  }

  .Designerscontent5 h3 {
    margin-left: 0;
  }

  .LandingPageContent2 {
    font-size: 40px;
    margin-top: 35%;
  }

  .LandingPageContent1 {
    font-size: 30px;
    color: white;
    z-index: 20px;
  }

  .samp img {
    width: auto;
    height: 120px;
  }
}

@media only screen and (min-width: 761px) and (max-width: 1000px) {
  .backgroung {
    height: 60px !important;
  }
}

@media screen and (device-width: 1024px) {
  .backgroung {
    height: 55px;
  }
}

.animatedtitle > div {
  height: 58%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  letter-spacing: 0.06em;
}

/* .animatedtitle > div.texttop div span:first-child {
    color: rgb(255 255 255);
    font-family: "poppins", sans-serif;
    font-size: 22px;
    text-align: center;
    /* font-weight: 500; */

.animatedtitle {
  color: rgb(255 255 255);
  font-family: "poppins", sans-serif;
  height: 70%;
  left: 50%;
  position: absolute;
  top: 60%;
  transform: translate(-50%, -50%);
  width: 80%;
  display: flex;
  justify-content: center;
  letter-spacing: 0.01em;
}

.animatedtitle {
  color: rgb(255 255 255);
  font-family: "poppins", sans-serif;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 60%;
  transform: translate(-50%, -50%);
  width: 80%;
  display: flex;
  justify-content: center;
  letter-spacing: 0.01em;
}

.animatedtitle > div div {
  font-size: 20px;
  text-align: center;
  padding: 2vmin 0;
  position: absolute;
}

.animatedtitle > div.textbottom {
  bottom: 0;
}

div.textbottom div {
  margin-top: 0;
}

.MainHeader1 {
  position: absolute;
  background: transparent;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
}

.animatedtitle > div.texttop div span:first-child {
  color: rgb(255 255 255);
  font-family: "poppins", sans-serif;
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: center;
}

.animatedtitle > div.texttop div span:first-child {
  color: rgb(255 255 255);
  font-family: "poppins", sans-serif;
  font-size: 22px;
  letter-spacing: 0.02em;
  font-weight: 500;
  text-align: center;
}

.fade-left {
  animation: fadeInLeft 1s ease-in-out;
}

.fade-right {
  animation: fadeInRight 1s ease-in-out;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.Contact_UsButtonHeader {
  justify-content: center;
  width: 100%;
  display: flex !important;
}

.Contact_UsButton {
  width: 100% !important;
}

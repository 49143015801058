.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  color: #fff !important;
}

/* .navbar-expand-lg .navbar-collapse {
  display: flex!important;
  flex-basis: auto;
  justify-content: center;
} */

.navbar-expand {
  /* flex-wrap: nowrap; */
  /* justify-content: flex-start; */
  display: block;
}

.navbar-expand {
  display: flex;
  justify-content: space-around;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
}

#basic-nav-dropdown {
  padding: 1rem;
}

.nav-link {
  padding: 1rem !important;
}

.swiper-pagination-bullet {
  background-color: orangered !important;
  margin-top: 2rem;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute !important;
  top: 50% !important;
  margin: 0rem 0rem;
  width: calc(var(--swiper-navigation-size) / 30 * 10) !important;
  height: var(--swiper-navigation-size) !important;
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: orangered !important;
  font-size: 20px !important;
  font-weight: bolder !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
}

.swiper-pagination-bullet-active {
  background-color: black !important;
}

.swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 10px)
  ) !important;
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 10px)
  ) !important;
  display: inline-block !important;
  border-radius: 50%;
  background-color: var(
    --swiper-pagination-bullet-inactive-color,
    var(--yellowColor)
  );
  opacity: 1 !important;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  align-items: center !important;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .swiper-wrapper {
    display: flex;
    align-items: center !important;
  }
}

.navbar-expand-lg .navbar-nav .nav-link {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  /* cursor: not-allowed; */
}

@media only screen and (max-width: 768px) {
  .nav-link {
    padding: 9px !important;
    font-size: 15px !important;
  }
}

.buttonStyle {
  display: flex;
}

.buttonStyle > button {
  padding: 8px;
  margin: 8px;
}

.techPartnershipCard {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: bolder;
  font-size: 14px;
  text-align: center;
  height: 100%;
  border: none;
  border-radius: 0;
  white-space: pre-wrap;
  word-break: break-word;
  color: #0066aa;
  background-color: white;
}

.contactBtn {
  position: absolute;
  left: 50%;
  bottom: 3%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  color: white;
  border: 2px solid #1111aa;
}

.contactBtn::after {
  content: "   Contact Us";
}

.contactBtn:hover {
  color: white;
}
.techPartnershipCard .header {
  color: #042e76;
  font-size: 16px;
  border-radius: 0;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .techPartnershipCard {
    font-size: 2px;
  }
  .techPartnershipCard .header {
    font-size: 4px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .techPartnershipCard {
    font-size: 8px;
  }
  .techPartnershipCard .header {
    font-size: 10px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .techPartnershipCard {
    font-size: 12px;
  }
  .techPartnershipCard .header {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 2560px) {
  .techPartnershipCard {
    font-size: 20px;
  }
  .techPartnershipCard .header {
    font-size: 22px;
  }
}

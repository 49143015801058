:root {
  --defaultRegular: "Poppins-Regular";
  --defaultSemiBold: "Poppins-SemiBold";
  --defaultLetterSpace: "";
}

@font-face {
  font-family: "poppins" "Poppins-Regular";
  src: local("Poppins"), url("../Fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "poppins" "Poppins-SemiBold";
  src: local("Poppins"), url("../Fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: normal;
}

body {
  font-family: "poppins" "Poppins-SemiBold";
}

svg {
  vertical-align: center;
}

.inputcontent {
  padding: 0 15px;
  height: 50px;
  width: 90%;
  border-radius: 6px;
  font-weight: normal;
  font-size: 15px;
  color: #000;
  background: #fff;
  border: none;
  box-shadow: 0 2px 13px 0 rgb(0 0 0 / 4%);
  transition: all 0.35s ease-out;
  font-family: "poppins", sans-serif;
}
.input select textarea {
  background: rgb(189, 176, 176);
  border: none;
}
.alertmgs {
  color: #e22a2a;
  font-size: 13px;
  font-weight: 500;
}
.modal-header .btn-close {
  background-color: white;
  margin-right: 0.5em !important;
}

.Modalbody {
  border-radius: 6px;
  background: #f3f6f9;
  box-shadow: 0 10px 40px 0 rgb(0 0 0 / 20%);
  text-align: left;
  overflow-y: auto;
}
.modalheader {
  font-size: 25px;
  justify-content: center;
  text-align: center;
  padding: 0px 0px 20px 0px;
  color: #f3f6f9;
  align-items: center;
}
#modalcontainer {
  padding: 1rem;
}

.btn button {
  width: 100%;
  background: #3eabfe;
  border: 2px solid #3eabfe;
  color: #fff;
  padding: 7px 35px;
  border-radius: 10px;
  font-size: 15px;
  margin-top: 1rem;
}
.btn button:hover {
  width: 100%;
  background: #007ad7;
  border: 2px solid #007ad7;
  color: #fff;
  padding: 7px 35px;
  border-radius: 10px;
  font-size: 15px;
  margin-top: 1rem;
}
.btn1 button {
  width: 100%;
  background: #e22121;
  border: 2px solid #e22a2a;
  color: #fff;
  padding: 7px 35px;
  border-radius: 10px;
  font-size: 15px;
  margin-top: 1rem;
}
.btn1 button:hover {
  width: 100%;
  background: #9f0000;
  border: 2px solid #9f0000;
  color: #fff;
  padding: 7px 35px;
  border-radius: 10px;
  font-size: 15px;
  margin-top: 1rem;
}

.Mainbtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 1px;
}
.inputcontent1 {
  height: 50px;
  padding: 0 15px;
  width: 100%;
  border-radius: 6px;
  font-weight: normal;
  font-size: 15px;
  color: #000;
  background: #fff;
  border: none;
  /* box-shadow: 0 2px 13px 0 rgb(0 0 0 / 4%);
  transition: all 0.35s ease-out; */
  font-family: "poppins", sans-serif;
}
.form-control {
  width: 90%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
  border: none;
}

.inputcontent3 {
  padding: 0 12px 90px;
  height: 120px;
  width: 90%;
  border-radius: 6px;
  font-weight: normal;
  font-size: 15px;
  color: #000;
  background: #fff;
  border: none;
  font-family: "poppins", sans-serif;
}
.Submitcontent {
  justify-content: center;
  text-align: center;
  color: #3eabfe;
  font-size: 18px;
  align-items: center;
}
.paracontent {
  font-size: 15px;
  color: #000;
}

.labelColor {
  color: #e22121;
  font-size: 20px;
}

input select {
  font-weight: normal;
  font-size: 0.875em;
  color: #000;
  background: #fff;
  border: none;
  box-shadow: 0 2px 13px 0 rgb(0 0 0 / 4%);
  border-radius: 6px;
  padding: 0 10px;
  transition: all 0.35s ease-out;
  font-family: "poppins", sans-serif;
}
.modalhead {
  padding: 0 !important;
  background-color: #0e1257;
  background: linear-gradient(
    90deg,
    rgba(1, 12, 84, 0.77) 0.63%,
    #089be2 53.76%,
    rgba(4, 46, 118, 0.87) 100%
  ) !important;
}
.btn-close:hover {
  color: rgb(255, 0, 0);
  text-decoration: none;
  opacity: 0.75;
}
.modalheader {
  font-size: 25px;
  padding: 10px !important;
  color: #f3f6f9;
  display: flex;
  justify-content: center;
  text-align: center;
}

@media (min-width: 320px) and (max-width: 425px) {
  .inputcontent {
    padding: 0 15px;
    height: 50px;
    width: 100%;
  }

  .inputcontent1 {
    height: 50px;
    width: 100%;
    font-size: 15px;
  }
  .labelcontent {
    padding: 0rem;
  }
  .Modalbody {
    padding: 10px;
  }
  .inputcontent3 {
    width: 100%;
  }

  .Submitcontent {
    padding: 0;
    margin-top: 0;
    font-size: 18px;
  }
  #services {
    width: 100%;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .inputcontent {
    padding: 0 15px;
    height: 50px;
    width: 100%;
  }
  .labelcontent {
    padding: 0;
  }
  #services {
    width: 100%;
  }

  .Modalbody {
    padding: 10px;
    width: 100%;
  }
  .inputcontent3 {
    width: 100%;
  }
  .inputcontent1 {
    width: 100%;

    height: 100%;
    padding: 0;
  }
  .Submitcontent {
    padding: 0;
    font-size: 18px;
    margin-top: 0;
  }

  #colhead {
    width: 100%;
  }
}

.warpper {
  position: relative;
  height: 250px;
  width: 100%;
  margin: auto;
  display: grid;
  /* place-items: center; */
  overflow: hidden;
}
.trackClient {
  display: flex;
  width: calc(450 * 6);
  animation: clientScroll 30s linear infinite;
}
.trackTechStack {
  display: flex;
  width: calc(450 * 6);
  animation: techStackScroll 30s linear infinite;
}
@keyframes clientScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-4000px * 1));
  }
}

@keyframes techStackScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-2750px * 1));
  }
}
.slide {
  width: 250px;
  height: 200px;
  display: flex;
  align-items: center;
  padding: 15px;
  overflow: hidden;
  perspective: 100px;
  /* cursor: pointer; */
}
.slide img {
  width: 100%;
  height: 100px;
  padding: 2rem;
  /* max-width: 100%;
  min-height: 100%;
  object-fit: cover; */
}

.swiperImage0 {
  width: 100% !important;
  height: 450px !important;
}
.swiperImage1 {
  width: 100% !important;
  height: 140px !important;
}
.swiperImage2 {
  width: 100% !important;
  height: 160px !important;
}
.swiperImage3 {
  width: 100% !important;
  height: 155px !important;
}
.swiperImage4 {
  width: 250px !important;
  height: 160px !important;
}
.swiperImage5 {
  width: 250px !important;
  height: 155px !important;
}
.swiperImage6 {
  width: 300px !important;
  height: 400px !important;
}
.swiperImage7 {
  width: 300px !important;
  height: 250px !important;
}
.swiperImage8 {
  width: 280px !important;
  height: 220px !important;
}
.swiperImage9 {
  width: 300px !important;
  height: 180px !important;
}
.swiperImage10 {
  width: 300px !important;
  height: 180px !important;
}
.swiperImage11 {
  width: 100% !important;
  height: 180px !important;
}
.swiperImage12 {
  width: 100% !important;
  height: 170px !important;
}
.swiperImage13 {
  width: 100% !important;
  height: 180px !important;
}
.swiperImage14 {
  width: 100% !important;
  height: 180px !important;
}
.swiperImage15 {
  width: 100% !important;
  height: 180px !important;
}
.swiperImage16 {
  width: 100% !important;
  height: 180px !important;
}
.swiperImage17 {
  width: 250px !important;
  height: 290px !important;
}
.swiperImage18 {
  width: 270px !important;
  height: 250px !important;
}
.swiperImage19 {
  width: 250px !important;
  height: 200px !important;
}
.swiperImage20 {
  width: 300px !important;
  height: 200px !important;
}
.swiperImage21 {
  width: 300px !important;
  height: 200px !important;
}
.jj {
  width: 100% !important;
  height: 200px !important;
}
.swiperImageURA {
  width: 100% !important;
  height: 200px !important;
}
.swiperImageUganda {
  width: 100% !important;
  height: 180px !important;
}
.swiperImageTNASDC {
  width: 100% !important;
  height: 150px !important;
}
.swiperImageNanMudhalvan {
  width: 100% !important;
  height: 230px !important;
}
.swiperImageVimarshi {
  width: 300px !important;
  height: auto !important;
}
/* .warpper::before,
.warpper::after{
  background: linear-gradient(to right,rgba(255,255,2551) 0%, rgba(255,255,255,0) 100%);
   content: '';
   position: absolute;
   height: 100%;
   width: 15%;
   z-index: 2;
}
.warpper::before{
  left: 0;
  top: 0;
} */

/* .slider-warpper{
  position: relative;
  height: 250px;
  width: 90%;
  margin: auto;
  display: grid;
  place-items: center;
  overflow: hidden;
}
.slider-track{
  display: flex;
  width: calc(250*6);
}
.slide{
  width: 250px;
  height: 200px;
  display: flex;
  align-items: center;
  padding: 15px;
  overflow: hidden;
  perspective: 100px;
  cursor: pointer;
}
.slide img{
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;

} */

.ClientsHeader {
  display: flex;
  justify-content: center;
}
.Clients {
  display: flex;
  justify-content: center;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 51px;
  /* line-height: 39px; */
  text-align: center;
  color: #ff6300;
  /* margin: 2rem 0; */
}
/* .headerClients {
  padding: 1rem;
  margin: 2rem 0;
} */
.MembershipHeader {
  box-sizing: border-box;
  border: 3.2px solid #e94057;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mySwiper {
  height: 30vh !important;
}
.Membership {
  display: flex;
  justify-content: center;
  padding: 4rem;
}

.MembershipHeader p {
  background: linear-gradient(180deg, #e94057 0%, #f27121 100%);
  display: flex;
  justify-content: center;
  color: #fdfdfd;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  align-items: center;
  padding: 0.5rem;
}
.paginationSwip img {
  width: 150px;
  /* height: 130px !important; */
  text-align: center;
}
.paginationSwip2 img {
  width: 160px;
  height: 160px !important;
  object-fit: cover;
  /* margin-left: 110px;
  text-align: center; */
}
.paginationSwip1 img {
  width: 130px;
  height: 135px !important;
  text-align: center;
}
.paginationSwip3 img {
  width: 190px;
  height: 165px !important;
  text-align: center;
}
.NasscomAndNSRHeader {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.SwiperSlideHeader {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.mySwiper {
  width: 100%;
  height: 100%;
}
.swiper-pagination {
  /* position: absolute; */
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.paginationSwip {
  width: 20%;
  text-align: center;
}

.swiperImageMlHUD {
  width: 50%;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  @keyframes clientScroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-4900px * 1));
    }
  }
  @keyframes techStackScroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-3650px * 1));
    }
  }
  .swiperImage {
    width: 70px;
  }
  .Nascom {
    width: 120px;
  }
  .NasscomAndNSRHeader {
    padding: 1rem;
  }
  .MembershipHeader p {
    font-size: 16px;
  }
  .swiper-wrapper {
    display: flex;
    align-items: center !important;
  }
  .Clients {
    font-size: 40px;
  }
  .paginationSwip img {
    width: 150px;
    /* height: 130px !important; */
    text-align: center;
  }
  .paginationSwip2 img {
    width: 122px;
    height: 120px !important;
    padding: 10px;
    /* height: 120px !important;
    object-fit: cover; */
    /* margin-left: 110px;
    text-align: center; */
  }
  .paginationSwip3 img {
    width: 120px;
    height: 145px !important;
    text-align: center;
  }
  .paginationSwip1 img {
    width: 120px;
    padding: 10px;
    height: 120px !important;
    text-align: center;
  }
  /* .swiperImage0 {
    width: 60% !important;
    height: 250px !important;
  }
  .swiperImage1 {
    width: 70% !important;
    height: 80px !important;
  }
  .swiperImage2 {
    width: 70% !important;
    height: 80px !important;
  }
  .swiperImage3 {
    width: 60% !important;
    height: 80px !important;
  }
  .swiperImage4 {
    width: 80% !important;
    height: 80px !important;
  }
  .swiperImage5 {
    width: 80% !important;
    height: 80px !important;
  }
  .swiperImage6 {
    width: 80% !important;
    height: 250px !important;
  }
  .swiperImage7 {
    width: 70% !important;
    height: 150px !important;
  }
  .swiperImage8 {
    width: 70% !important;
    height: 150px !important;
  }
  .swiperImage9 {
    width: 90% !important;
    height: 100px !important;
  }
  .swiperImage10 {
    width: 70% !important;
    height: 130px !important;
  }
  .swiperImage11 {
    width: 60% !important;
    height: 120px !important;
  }
  .swiperImage12 {
    width: 70% !important;
    height: 110px !important;
  }
  .swiperImage13 {
    width: 70% !important;
    height: 130px !important;
  }
  .swiperImage14 {
    width: 70% !important;
    height: 100px !important;
  }
  .swiperImage15{
    width: 70% !important;
    height: 100px !important;
  } 
  .swiperImage img{
    width: 100% !important;
    height: 10px !important;
  }*/
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  @keyframes clientScroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-4700px * 1));
    }
  }
  @keyframes techStackScroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-3350px * 1));
    }
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1300px) {
  @keyframes clientScroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-4150px * 1));
    }
  }
  @keyframes techStackScroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-2900px * 1));
    }
  }
}

.solutioncard {
  border: 5px solid #994d37;
  background-color: transparent;
  padding: 0 10px;
  height: 100%;
  z-index: 1;
}

.solutioncard:hover {
  border-color: #4415ee;

  color: white;
  background-color: #2b224c;
}

.absoluteImage {
  position: relative;
  top: -40px;
  z-index: 2;
  left: 10px;
  width: 80px;
}
.absoluteImage::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  background-color: #ffdd89;
  z-index: -1;
}

.trackClient {
  display: flex;
  width: calc(450 * 6);
  animation: clientScroll 30s linear infinite;
}

@media only screen and (max-width: 768px) {
  .absoluteImage {
    position: relative;
    top: 0px;
    z-index: 2;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
  }
  .content {
    text-align: center;
  }
  .header {
    text-align: center;
  }
}
@media only screen and (max-width: 768px) {
  .missionContent {
    font-size: smaller;
  }
  .missionHeading {
    font-size: small;
  }
}

.header {
  margin-top: -25px;
  margin-bottom: 10px;
}

.missionCard {
  background-color: #ffdd89;
  padding: 30px;
  height: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.missionImage {
  width: 100%;
  margin-bottom: 10px;
}

.imageContent {
  width: 100%;
  text-align: center;
  /* margin-bottom: 10px; */
}
.animationCard {
  position: absolute;

  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2b224c;
  color: white;
  font-weight: bold;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.missionCard:hover .animationCard {
  height: 100%;
  padding: 20px 10px 10px 10px;
}

.sectionOne {
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 50px;
  font-family: "poppins", sans-serif;
}

.Clients {
  display: flex;
  justify-content: center;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 51px;
  /* line-height: 39px; */
  text-align: center;
  color: #ff6300;
}

.warpper {
  position: relative;
  height: 250px;
  width: 100%;
  margin: auto;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.trackClient {
  display: flex;
  width: calc(450 * 6);
  animation: clientScroll 5s linear infinite;
}

.slide {
  width: 250px;
  height: 200px;
  display: flex;
  align-items: center;
  padding: 15px;
  overflow: hidden;
  perspective: 100px;
}
.slide img {
  width: 100%;
  height: 100%;
  padding: 2rem;
}

@keyframes clientScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-1000px * 1));
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  @keyframes clientScroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-450px * 1));
    }
  }
  .Clients {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  @keyframes clientScroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-900px * 1));
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1300px) {
  @keyframes clientScroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-950px * 1));
    }
  }
}

.comments {
  /* margin-top: 3rem;
  padding: 3rem; */
  color: #ff6300;
  font-weight: 900;
  font-style: "poppins";
}
.comments h4 {
  color: #ff6300;
  margin-left: 0px;
}
.comments-title span {
  font-size: 17px;
  font-weight: 900;
}
.date {
  color: black;
  font-size: 14px;
  /* margin-right: 8px; */
}
/* .mb-3 {
  align-items: end;
  justify-content: center;
}
.Label {
  display: flex;
  justify-content: start;
} */
.Maincontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.commentcontainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 0.5rem;
}
.dash {
  border-bottom: 1px dashed black;
  margin-bottom: 20px;
}
.like {
  cursor: pointer;
  color: red;
  margin-left: 10px;
}

.comments-title {
  font-size: 22px;
  margin-bottom: 20px;
}

.comments-container {
  margin-top: 40px;
}

.comment-form-title {
  display: flex;
  justify-content: start;
  font-size: 22px;
  color: #ff6300;
  font-weight: 900;
  /* padding: 1rem; */
  font-family: "poppins", sans-serif;
  margin-top: 6rem;
}

.comment-form-textarea {
  margin-bottom: 8px;
  border-radius: 10px;
  font-size: 17px !important;
  margin-top: 30px;
  font-weight: 800;
  padding: 0.8rem 0.75rem;
}

.comment-form-button {
  display: flex;
  width: 6rem;
  font-size: 16px;
  padding: 8px 16px;
  background: rgb(59, 130, 246);
  border-radius: 8px;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.comment-form-button:hover:enabled {
  cursor: pointer;
  background: rgb(37, 99, 235);
}

.comment-form-button:disabled {
  opacity: 0.7;
  cursor: default;
}

.comment-form-cancel-button {
  margin-left: 10px;
}

.comment {
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
  margin-left: 45px;
}

.comment-image-container img {
  border-radius: 50px;
  margin-left: -50px;
}

.comment-right-part {
  width: 100%;
}
.img {
  width: 50px;
  object-fit: cover;
}
/* .comment-content {
  display: flex;
  justify-content: space-between;
} */

.comment-author {
  font-size: 20px;
  color: black;
}

.comment-text {
  font-size: 15px;
  color: #6c757d;
  font-weight: 900;
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 1rem;
}

.comment-actions {
  display: flex;
  font-size: 12px;
  color: rgb(51, 51, 51);
  cursor: pointer;
  margin-top: 8px;
  display: flex;
  justify-content: end;
}

.comment-action {
  margin-right: 8px;
}
.comment-actio {
  padding-left: 0.5rem;
  border-left: 1px rgb(146, 141, 141) solid;
}
.comment-action:hover {
  text-decoration: underline;
}

.replies {
  margin-top: 20px;
}
.comment-actio:hover {
  text-decoration: underline;
}

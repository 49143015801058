.contentBox1 img {
  width: 100%;
  height: 100%;
}

.modal-body {
  padding: 0px !important;
  margin: 0 !important;
  line-height: 0;
}

#modalnewe {
  background-color: transparent !important;
}
/* .modal-dialog {
  margin: auto;
} */

.videoplayer {
  /* padding: 1rem 0rem; */
  width: 100%;
  height: auto;
}

.btn-pos {
  position: absolute;

  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  right: 0 !important;
  z-index: 1056 !important;
  color: white;
  font-weight: bold;
  font-size: 20px;
  line-height: 0.5;
}
.btn-pos:hover {
  color: rgba(255, 255, 255, 0.8);
}
#my-modal {
  width: 100vw; /* Occupy the 100% of the screen width */
  max-width: 45vw;
  display: flex;
  /* align-items: stretch; */
}

@media (min-width: 320px) and (max-width: 426px) {
  #my-modal {
    width: 96%;
    max-width: 100%;
    /* max-height: 20vh; */
  }
  .contentBox1 img {
    width: 100%;
    height: 100%;
  }
  button.btn-close {
    width: 2em !important;
  }
  #modalnewe {
    height: 0px;
  }
}
@media (min-width: 427px) and (max-width: 1024px) {
  #my-modal {
    width: 70%;
    max-width: 100%;
  }
}

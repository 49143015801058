/* Initial state for fade-in animation */
.fade-in {
  opacity: 0;
  transform: translateY(20px); /* Start slightly below */
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out; /* Transition effect */
}

/* When the element becomes visible (animated state) */
.fade-in.animated {
  opacity: 1;
  transform: translateY(0); /* Move to original position */
}

/* Keyframes for slide-down animation */
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Final position */
  }
}

/* Add the delay between text animations */
.fade-in:nth-child(1) {
  animation-delay: 0s;
}

.fade-in:nth-child(2) {
  animation-delay: 0.3s; /* Delay for the second paragraph */
}

.fade-in:nth-child(3) {
  animation-delay: 0.6s; /* Delay for the third paragraph */
}

.fade-in:nth-child(4) {
  animation-delay: 0.9s; /* Delay for the button */
}

p {
  color: black;
}

.slide-down {
  animation: slideDown 1s ease-in-out;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes zoomInOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1); /* Slight zoom-in */
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes colorChange {
  0% {
    background-color: #010c54; /* Initial color */
    border-color: #010c54;
    font-weight: bold;
  }
  50% {
    background-color: #010c54; /* Light blue color halfway through */
    border-color: #010c54;
    /* color: #010c54; */
    font-weight: bold;
  }
  100% {
    background-color: #010c54; /* Original color at the end */
    border-color: #010c54;
    font-weight: bold;
  }
}

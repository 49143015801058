.navitemColor {
  color: white !important;
  cursor: pointer;
}
p {
  font-family: "poppins", sans-serif;
}

.li {
  position: relative;
}
.li::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
  background-color: red;
  width: 100%;
  height: 5px;
  transform: scaleX(0);
  transition: transform 350ms linear;
  transform-origin: right;
}

.li:hover a {
  color: orange !important;
}
.li:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}
.navBoxShadow {
  /* background-color: #424242; */
  background-color: transparent;
  padding: 2% 5%;
  /* box-shadow: 1px 1px 8px rgb(237, 234, 234); */
  font-size: 18px !important;
  font-family: "poppins", sans-serif;
  font-weight: 550 !important;
}

.heroSection {
  background-image: url(../../Images/products/productImage.png);
  padding-top: 10%;
  padding-bottom: 12%;
  position: relative;

  /* background-image: linear-gradient(
    to right top,
    #03045e,
    #00267a,
    #004494,
    #0061aa,
    #047ebe
  ); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.heroSection h1 {
  font-family: "poppins", sans-serif;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 5%;
}
.paragraph {
  text-align: justify;
  font-family: "poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.heroSection h1 span:nth-child(1) {
  color: #0045f6;
}

.middleCard {
  background-color: white;
  margin-top: 2%;
  padding: 2%;
}

.heroButton {
  background-color: #0045f6;
  font-family: "poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 1% 4%;
  margin-top: 2%;
}

.heroButton:hover {
  background-color: #0045f6;
  color: black;
}

.heroButton:active {
  background-color: #0045f6;
  color: black;
}
.phone {
  transform: rotateY(180deg);
}

.whyUsCard {
  box-shadow: -1px 0px 10px 3px #0087ff;
  text-align: center;
  margin-top: 15%;
  height: 90%;
  padding: 6px;
  position: relative;
}

.whyUsCard:hover {
  box-shadow: none;
  transition: box-shadow 150ms linear;
}
.whyUsCard::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
  background-color: orange;
  width: 30%;
  height: 5px;
  transition: width 350ms linear;
}

.whyUsCard:hover:after {
  width: 100%;
}
.whyUsCard .image {
  margin-top: 10%;
}

.whyUsCard h3 {
  font-size: 17.5px;
  margin-top: 10%;
  color: #0045f6;
}
.whyUsCard p {
  font-size: 16px;
  font-weight: 500;
  font-family: "poppins", sans-serif;
  margin-top: 10%;
}
.whyUs {
  padding-top: 5%;
  padding-bottom: 5%;
}
.whyUs h1 {
  font-size: 40px;
  color: #0045f6;
  font-weight: 600;
  font-family: "poppins", sans-serif;
}

.whyUsCol {
  /* background-color: #fdfdfd; */
  border-radius: 10px;
  font-weight: normal;
  line-height: 1.8;
  font-size: 18px;
  text-align: justify;
  font-family: "poppins", sans-serif;
  margin-top: 1%;
  padding: 10px;
}

.toolsUsed {
  background-color: rgb(0, 158, 251);
  padding-top: 2%;
  padding-bottom: 2%;
}
.toolsUsed h1 {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  font-family: "poppins", sans-serif;

  color: rgb(1, 12, 84);
}

.toolsUsedCol {
  background-color: white;
  text-align: center;
  margin-top: 2%;
  /* margin-bottom: 2%; */
  margin-left: 1%;
  padding: 1%;
  overflow: hidden;
  transition: transform 650ms linear;
}
.toolsUsedCol:hover {
  transform: translateY(-10px);
}

.toolsUsedCol h3 {
  font-family: "poppins", sans-serif;
  font-weight: 700;
  font-size: 25px;
  color: rgb(1, 12, 84);
}

.ourFeatures {
  background-color: white;
  padding-top: 3%;
  padding-bottom: 3%;
}
.ourFeaturesCol h1 {
  color: #fff;
  font-family: "poppins", sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 23px;
  padding-top: 5%;
  padding-bottom: 5%;
}
.ourFeaturesCol p {
  color: #fff;
  font-family: "poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.heading {
  color: #0045f6;
  text-align: center;
  /* padding-top: 2%; */
}
.ourFeaturesCol {
  background-color: rgb(0, 158, 251);
  text-align: center;
  position: relative;
  padding: 3%;
  margin: 1%;
  border-style: solid;
  border-color: #010c54 !important;
  border-width: 1.5px;
  border-radius: 10px;
  padding: 20px;
  transition: transform 350ms linear;
  overflow: hidden;
}
.ourFeaturesCol:hover {
  transform: scale(0.95);
}

.featureNum {
  position: absolute;
  top: 0;
  left: 0;
  background-color: orange;
  padding: 1% 2%;
}
.ourSolutions {
  background-color: #b3c7fc;
  padding-bottom: 5%;
  padding-top: 4%;
}

.ourSolutions h1:nth-child(1) {
  color: #0045f6;
  /* padding-top: 2%; */
}

.tabParent {
  margin-top: 2%;
  align-items: stretch;
}

.tab {
  background-color: #010c54 !important;
  color: #fff;
  font-family: "poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  border-left: 1px solid white;
  padding-block-start: 10px;
  padding-block-end: 10px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  cursor: pointer;
}
.tab1 {
  background-color: #010c54 !important;
  color: #fff;
  font-family: "poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  align-items: center;
  border-radius: 50px 0 0 50px;
  padding-block-start: 10px;
  padding-block-end: 10px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  cursor: pointer;
}
.tab2 {
  background-color: #010c54 !important;
  color: #fff;
  font-family: "poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  border-radius: 0 50px 50px 0;
  padding-block-start: 10px;
  padding-block-end: 10px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  border-left: 1px solid white;
  cursor: pointer;
}
.active {
  background-color: #1d38e2 !important;
  color: white;
  font-family: "poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  border-left: 1px solid white;
  padding-block-start: 10px;
  padding-block-end: 10px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  cursor: pointer;
}

.active2 {
  background-color: #1d38e2 !important;
  color: white;
  font-family: "poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;

  border-radius: 0 50px 50px 0;
  padding-block-start: 10px;
  padding-block-end: 10px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  border-left: 1px solid white;
}
.active1 {
  background-color: #1d38e2 !important;
  color: white;
  font-family: "poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  align-items: center;
  border-radius: 50px 0 0 50px;
  padding-block-start: 10px;
  padding-block-end: 10px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
}
.tab:hover {
  background-color: #1d38e2 !important;
  color: white;
}
.tab1:hover {
  background-color: #1d38e2 !important;
  color: white;
}
.tab2:hover {
  background-color: #1d38e2 !important;
  color: white;
}

.tabCol {
  font-family: "poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: rgb(0, 0, 0);
}

.ourClients {
  background-color: #f6f9fb;
  color: rgb(1, 12, 84);
  position: relative;
  padding-top: 3%;
  padding-bottom: 3%;
  font-size: 60px;
}
#elina {
  position: relative;
  z-index: 2;
}
#mfi {
  position: relative;
  z-index: 2;
}
.design {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.accordion {
  border: 1px solid white;
  margin-right: 5%;
}
.accordionHeader {
  padding: 10px;
  margin-bottom: 5px;
  background-color: #010c54;
  color: white;
  font-family: "poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.accordionHeaderActive {
  padding: 10px;
  margin-bottom: 5px;
  background-color: #2d3fb4;
  color: white;

  font-family: "poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.accordionHeader1 {
  padding: 10px;
  background-color: #010c54;
  color: white;

  font-family: "poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.accordionHeader1Active {
  padding: 10px;
  background-color: #2d3fb4;
  color: white;

  font-family: "poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.accordionHeader span:nth-child(1) {
  padding-left: 10px;
  font-size: x-large;
}

.accordionHeader1 span:nth-child(1) {
  padding-left: 10px;
  font-size: x-large;
}

.accordionHeaderActive span:nth-child(1) {
  padding-left: 10px;
  font-size: x-large;
}

.accordionHeader1Active span:nth-child(1) {
  padding-left: 10px;
  font-size: x-large;
}

.clientHeader {
  font-size: 40px;
  font-family: "poppins", sans-serif;
  font-weight: 600;
  color: rgb(1, 12, 84);
  padding-top: 5%;
  text-align: center;
}
.clientSubHeader {
  font-family: "poppins", sans-serif;
  color: rgb(0, 0, 0);
  text-align: center;
  /* margin-top: 20px; */
  margin-bottom: 20px;
}

.accordCollapse {
  font-weight: 600;
  padding: 10px;
  max-height: 300px;
  transition: max-height 250ms linear, opacity 250ms linear,
    padding 250ms linear;
}

.collapseAnim {
  font-weight: 600;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 250ms linear, opacity 250ms linear,
    padding 250ms linear;
}

.newsLetter {
  padding-top: 3%;
  padding-bottom: 3%;
  background-color: #424242;
}

.newsLetter h1 {
  color: white;
  font-family: "poppins", sans-serif;
  font-size: 32px;
  font-weight: normal;
  line-height: 1.8;
  text-align: center;
  letter-spacing: normal;
}

.newsLetter p {
  color: white;
  font-size: 18px;
  text-align: center;
}

.newsLetter input {
  background-color: transparent;
  border: 1px solid rgb(197, 195, 195);
  padding: 5px;
  margin: 0;
  width: 100%;
}

.newsLetter button {
  background-color: white;
  padding: 5px 15px;
  margin: 0;
}

.ourFeaturesCol img {
  width: 30% !important;
}

.hideTab {
  display: none;
  justify-content: center;
  margin-top: 1%;
}

.imgAlign {
  text-align: end;
}

@media only screen and (max-width: 768px) {
  .accordCollapse {
    font-size: 12px;
    font-weight: 600;
  }
  .collapseAnim {
    font-size: 12px;
    font-weight: 600;
  }
  .heroSection {
    padding: 15%;
  }

  .heroSection img {
    width: 100%;
    margin-top: 20%;
  }
}
@media only screen and (max-width: 992px) {
  .clientHeader {
    font-size: 30px;
  }
  .ourFeaturesCol img {
    width: 10% !important;
  }
  #tab {
    display: none;
  }
  .hideTab {
    justify-content: center;
    margin-top: 1%;
    display: flex;
  }
  .imgAlign {
    text-align: center;
    margin-bottom: 10px;
  }
  .ourClients h1:nth-child(1) {
    font-size: 40px;
    position: relative;
    z-index: 2;
  }
  #elina {
    width: 50%;
  }
  #mfi {
    width: 50%;
  }
}

@media only screen and (max-width: 1024px) {
  #tab {
    display: none;
  }
  .hideTab {
    justify-content: center;
    margin-top: 1%;
    display: flex;
  }
}
.accordTab {
  background-color: #010c54 !important;
  color: #fff;
  font-family: "poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  align-items: center;
  border-radius: 50px;
  padding-block-start: 10px;
  padding-block-end: 10px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
}
.accordActiveTab {
  background-color: #2d3fb4 !important;
  color: #fff;
  font-family: "poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  align-items: center;
  border-radius: 50px;
  padding-block-start: 10px;
  padding-block-end: 10px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  cursor: pointer;
}

@keyframes btnMain {
  0% {
    transform: translate3d(0, -100%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes showBottomText {
  0% {
    transform: translate3d(0, -100%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.pos {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btnMain > div.btn div {
  border-radius: 30em;
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  animation: showBottomText 0.9s;
  animation-delay: 2.75s;
  animation-fill-mode: forwards;
  top: 70%;

  transform: translate(0, 100%);
}

.btnMain {
  color: rgb(255 255 255);
  font-family: "poppins", sans-serif;
  left: 50%;
  position: absolute;
  top: 65%;
  transform: translate(-50%, -50%);
  width: 80%;
  display: flex;
  justify-content: center;
  letter-spacing: 0.01em;
}

.btnMain > div {
  height: 160px;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .pos {
    bottom: 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .pos {
    bottom: -2%;
  }
  .btnMain {
    color: rgb(255 255 255);
    font-family: "poppins", sans-serif;
    left: 50%;
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    width: 80%;
    display: flex;
    justify-content: center;
    letter-spacing: 0.01em;
  }
}

button.learn_more {
  width: 12rem;
  height: auto;
}

button.learn_more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  color: #fff;
  border-radius: 1.625rem;
}

button.learn_more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

button.learn_more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.learn_more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

button.learn_more .button_text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  letter-spacing: 0.09em;
  color: #fff;
  font-family: "poppins" var(--defaultRegular);
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

button:hover .circle {
  width: 100%;
}

button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

button:hover .button_text {
  color: #fff;
}

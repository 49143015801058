/* .CardText{
  background-color: #010c54;
} */
.card {
  display: flex;
  justify-content: flex-start;
}

.card1 {
  display: flex;
  justify-content: flex-end;
}

.swiperImage0 {
  width: 100%;
  height: 500px;
  padding: 0.5rem;
}

.dedicated {
  font-family: "poppins", sans-serif;
  font-size: 39px;
  font-weight: 800;
  letter-spacing: 0.03em;
}

.Peopleexcellence p {
  width: 100%;
  margin-top: 2rem;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
  /* text-transform: capitalize; */
  color: #ff6300;
  font-size: 39px;
  line-height: 51px;
}

.Peopleexcellence span {
  letter-spacing: 0.02em;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 19px !important;
  display: flex;
  align-items: center;
  width: 100% !important;
  color: #042e76;
}

.whoweareheader p {
  margin: 1rem 10px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.02em;
  /* text-transform: capitalize; */
  color: #ff6300;
  font-size: 39px;
  line-height: 51px;
}

.whoweareheader span {
  letter-spacing: 0.03em;
  font-family: "poppins", sans-serif;
  margin: 2rem 10px;
  line-height: 32px;
  font-style: normal;
  font-weight: 700;
  font-size: 19px !important;
  /* margin-bottom: -64px; */
  display: flex;
  align-items: center;
  text-align: justify;
  align-items: center;
  color: #042e76;
  /* font-family:"poppins" "Barlow Condensed"; */
}

#AboutMainheader {
  padding: 4.5rem 0rem;
  /* margin-top: 25px; */
}

.PartnersHeader {
  padding: 4.5rem;
  background-color: #ffffff;
}

.PartnersHeader p {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 39px;
  line-height: 64px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #010c54;
  justify-content: center;
}

.PartnersCounter {
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 64px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  justify-content: center;
  color: #ff6300;
}

.PartnersHeader h6 {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.04em;
  display: flex;
  align-items: center;
  text-align: center;
  color: #010c54;
  justify-content: center;
}

.Card {
  padding: 10px;
}

.CardImage img {
  width: auto;
  padding: 1rem;
  width: 350px !important;
  height: 350px !important;
}

.CardImage span {
  text-align: center !important;
}

.CardImage {
  /* width: 70%; */
  align-items: center;
  border: none;
  margin: 1em;
}

.linktinHeder {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}

.linktinHeder span {
  letter-spacing: 0.02em;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  display: flex;
  align-items: center;
  color: #1c1e24;
}

.position {
  font-family: "poppins", sans-serif;
  padding: 0.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #979797;
}

.position1 {
  border: 1px dashed #ff6300;
  box-sizing: border-box;
}

.peopleexcellence {
  letter-spacing: 0.02em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.5rem;
}

.peopleexcellence h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #010c54;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px dashed #ff6300;
  text-align: center;
  padding: 10px;
  margin-left: 1rem;
  width: 100%;
}

/* .Individual1 {
  height: 60px;
  margin-left: 1rem;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #010c54;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px dashed #ff6300;
  text-align: center;
  padding: 1rem;
} */

.Individual {
  margin-left: 1rem;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #010c54;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px dashed #ff6300;
  text-align: center;
  padding: 1rem;
}

.peopleexcellence span {
  display: flex;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #010c54;
  box-sizing: border-box;
  border: 1px dashed #ff6300;
}

.Regular {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #010c54;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  /* border: 1px solid; */
  border: 1px dashed #ff6300;
  text-align: center;
  padding: 1rem;
}

.peopleexcellence1 {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.peopleexcellence1 h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #010c54;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  /* border: 1px solid; */
  border: 1px dashed #ff6300;
  text-align: center;
  padding: 1rem;
}

.text::before {
  font-family: "poppins", sans-serif;
  content: " We Offer ";
  color: #010c54;
  font-size: 39px;
  letter-spacing: 0.03em;
}

.Benefits {
  font-weight: 700;
  font-size: 55px;
  line-height: 64px;
  letter-spacing: 1px;
  color: #ff6300;
  padding: 0.5em 0em;
}

/* .offer{
  background-color: #f5f5f5;
  padding: 3rem;
} */
.BenefitsCard {
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  padding: 1.5rem;
}

.BenefitsCard p {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  /* text-transform: uppercase; */
  color: #ff6300;
}

.BenefitsCard span {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #010c54;
  letter-spacing: 0.03em;
}

.BenefitsCard img {
  width: auto;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .PartnersHeader {
    padding: 2.5rem;
    background-color: #ffffff;
  }

  .Individual1 {
    width: 100%;
    height: 80px;
  }

  .BenefitsCard {
    height: 95%;
  }

  .CardImage {
    width: 100%;
    border: none;
    display: flex;
    margin-bottom: 2rem;
  }

  .CardImage img {
    width: 100% !important;
    height: auto !important;
  }

  .Peopleexcellence span {
    margin-bottom: 3rem !important;
  }

  .Peopleexcellence p {
    font-size: 40px;
  }

  #AboutMainheader {
    padding: 1rem;
  }

  .Videoplayer {
    padding: 1rem 0rem;
    width: 100%;
  }

  .Benefits {
    font-weight: 900;
    font-size: 30px;
    line-height: 40px;
    color: #010c54;
    margin-top: 0 !important;
  }

  .whoweareheader p {
    width: 100%;
    font-size: 39px;
    font-weight: 900;
    text-align: initial;
  }

  .PartnersHeader p {
    font-size: 26px;
    font-weight: 900;
    line-height: 30px;
  }

  .BenefitsCard {
    background: rgba(255, 255, 255, 0.8);
    margin: 1rem;
  }

  .text::before {
    font-size: 22px !important;
  }

  .dedicated {
    font-family: "poppins", sans-serif;
    font-size: 22px;
    font-weight: 800;
  }

  .whoweareheader span {
    font-size: 17px;
    letter-spacing: 0.09em;
    text-align: initial;
  }

  .BenefitsCard img {
    width: 12%;
    margin-bottom: 1rem;
  }

  .peopleexcellence {
    width: 100%;
    flex-direction: column;
    margin-top: 0;
  }

  .Individuals {
    margin-left: 0;
  }

  .peopleexcellence1 {
    display: flex;
    margin-top: 0;
    flex-direction: column;
  }

  .peopleexcellence h6 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .BenefitsCard {
    height: 90%;
    /* text-align: justify; */
  }

  .peopleexcellence {
    width: 100%;
    flex-direction: column;
  }

  .Individual {
    margin-left: 0;
  }

  .peopleexcellence {
    display: flex;
    margin-top: 0;
    flex-direction: column;
    width: 100%;
  }

  .Peopleexcellence span {
    margin-bottom: 3rem !important;
  }

  .Benefits {
    padding: 0.5em;
    margin-top: 0;
  }

  /* .CardImage img {
    width: 100%;
    height: 400px !important;
  } */
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .card {
    display: flex;
    justify-content: center !important;
  }

  .card1 {
    display: flex;
    justify-content: center !important;
  }

  .BenefitsCard {
    background: rgba(255, 255, 255, 0.8);
    height: 95%;
    padding: 1.5rem 1rem;
  }

  .Peopleexcellence {
    letter-spacing: 0.02em;
    font-family: "poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 19px !important;
    display: flex;
    align-items: center;
    width: 100% !important;
    color: #042e76;
  }

  #AboutMainheader {
    padding: 2.5rem 0rem;
  }

  .Peopleexcellence p {
    width: 100%;
    margin: 0rem 1rem;
    margin-top: 0rem;
    font-family: "poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1px;
    /* text-transform: capitalize; */
    color: #ff6300;
    font-size: 39px;
    line-height: 51px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .BenefitsCard {
    background: rgba(255, 255, 255, 0.8);
  }

  .Benefits {
    font-weight: 700;
    font-size: 45px;
    line-height: 64px;
    letter-spacing: 1px;
    text-transform: capitalize;
    color: #010c54;
  }

  .whoweareheader span {
    font-size: 17px;
  }

  /* .BenefitsCard img {
    width: 8%;
    margin-bottom: 2rem;
  } */
  .MangementTeamHeader1 {
    padding-top: 0rem;
  }

  .CardImage img {
    width: 340px !important;
    height: 330px !important;
  }

  .CardImage {
    margin-top: 2rem;
  }
}

/* WhoCanBenefit.css */

/* Fade-in Animation */
.fade-in {
  opacity: 0;
  animation: fadeIn 1.5s forwards;
}

/* Keyframes for Fade-in */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Additional styling for the fade-in effect */
.text-center p {
  animation: none; /* Remove animation from heading */
}

.backgroung {
  /* background: #202641; */
  /* background-image: linear-gradient(to right, #031137, #0f214e, #1d3165, #2a437e, #365598, #365598, #365598, #365598, #2a437e, #1d3165, #0f214e, #031137); */
  height: 80px;
  background-image: linear-gradient(
    to left,
    #130084,
    #090f79,
    #08176e,
    #0e1c61,
    #151f54,
    #282051,
    #34224d,
    #3c254a,
    #54254b,
    #692547,
    #7b283d,
    #883131
  );
}
.even {
  list-style: none;
}
.odd {
  list-style: none;
}
#rows {
  display: contents;
}
.careerlandimage {
  background-image: url(../../Images/blogland.png);
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  height: 700px;
}

.container {
  background-image: linear-gradient(
    to right top,
    #2160bb,
    #1f50ae,
    #2140a0,
    #232f91,
    #251d82
  );
  height: 400px;
  width: 100%;
}

.color {
  color: #ff2c00;
  font-size: 20px;
}
.LandingPageContent {
  position: absolute;
  top: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
}
.LandingPageContent p {
  font-family: "poppins", sans-serif;
  font-weight: 900;
  font-size: 50px;
  color: #ffffff;
  /* opacity: 70%; */
  padding: 5rem 0rem 2rem 0rem;
}

.LandingPageContent h6 {
  font-family: "poppins", sans-serif;
  font-weight: 900;
  font-size: 35px;
  /* text-transform: uppercase; */
  color: #fff;
}
.link {
  text-decoration: none;
}
/* .text:hover {
  -ms-transform: scale(1.5); 
  -webkit-transform: scale(1.5); 
  transform: scale(1.5); 
}
.text{
  transition: transform .2s;
} */
.card {
  display: flex;
  /* justify-content: space-around; */
  /* width: 100% !important; */
  height: 300px !important;
  background-color: #2c3e50;
  padding: 15px;
  border-radius: 10px;
}
.card img {
  width: 40%;
  height: auto;
  border-radius: 10px;
}

.card_r {
  display: flex;
  justify-content: space-around;
  width: 100% !important;
  height: 300px !important;
  background-color: #1c6443;
  padding: 15px;
  margin-top: 18rem;
  border-radius: 10px;
}
.card_r img {
  width: 40%;
  height: auto;
  border-radius: 10px;
}
.zoomimg {
  width: 600px;
  overflow: hidden;
}
.zoomimg img {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.zoomimg img:hover {
  transform: scale(1.2);
}

.text {
  font-family: "poppins", sans-serif;
  font-size: 30px;
  font-weight: 900;
  line-height: initial;
  padding: 1rem;
  color: rgb(255, 255, 255);
  text-decoration: none;
}

/* .pht1 {
  width: 100% !important;
    height: 300px !important;
    background-color:  #008638 ;
    padding: 15px; 
} */

#head {
  height: 300px;
  padding: 0px;
  margin-left: 10px;
  cursor: pointer;
}

#head_r {
  margin-top: 300px;
  height: 300px;
  padding: 0px;
  margin-left: 10px;
  cursor: pointer;
}

#head1 {
  text-decoration: none;
  background-color: #2c3e50;
  color: antiquewhite;
  height: 300px;
  padding: 15px;
  margin-right: 70px;
  width: 30%;
  cursor: pointer;
}

#head2 {
  text-decoration: none;
  background-color: #008638 !important;
  color: antiquewhite;
  height: 300px;
  margin-top: 300px;
  padding: 10px;
  width: 30%;
  cursor: pointer;
}

#head3 {
  text-decoration: none;
  background-color: #ff4f74;
  color: antiquewhite;
  height: 300px;
  padding: 10px;
  margin-right: 70px;
  width: 30%;
  cursor: pointer;
}

#head4 {
  text-decoration: none;
  background-color: #104aa0 !important;
  color: antiquewhite;
  height: 300px;
  margin-top: 300px;
  padding: 10px;
  width: 30%;
  cursor: pointer;
}

#head5 {
  text-decoration: none;
  background-color: #0f5132 !important;
  color: antiquewhite;
  height: 300px;
  padding: 10px;
  margin-right: 70px;
  width: 30%;
  cursor: pointer;
}

#head6 {
  text-decoration: none;
  background-color: #ff2c00 !important;
  color: antiquewhite;
  height: 300px;
  margin-top: 300px;
  padding: 10px;
  width: 30%;
  cursor: pointer;
}

@media only screen and (min-width: 320px) and (max-width: 430px) {
  .card {
    display: flex;
    /* justify-content: space-around; */
    /* width: 100% !important; */
    height: 250px !important;
    background-color: #2c3e50;
    padding: 15px;
    border-radius: 10px;
  }
  .card_r {
    margin-top: 3rem;
  }

  .careerlandimage {
    /* background-image: url(../../Images/blogland.png); */
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    height: 390px;
    /* margin-bottom: 30px; */
  }
  .LandingPageContent {
    position: absolute;
    top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
  }
  .LandingPageContent p {
    font-weight: 500;
    /* opacity: 70%; */
    font-size: 30px;
    line-height: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    margin-top: 0px;
  }

  .LandingPageContent h6 {
    font-weight: normal;
    font-size: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #fff;
    padding: 0 0rem;
    margin: 0rem 0em;
  }

  .pht {
    width: 100% !important;
    height: 250px !important;
  }

  .pht_right {
    width: 100%;
    height: 250px;
  }

  .content_word {
    float: right;
    position: relative;
    top: 10%;
    display: contents;
    /* font-size: 61px; */
    justify-content: center;
  }

  #head {
    /* background-color: hsla(330.7792207792208, 100.00%, 69.80%, 1.00) !important; */
    height: 250px;
    padding: 0px;
    margin-left: 0px;
  }

  #head_r {
    margin-top: 0%;
    height: 100%;
    padding: 0px;
    margin-left: 0px;
  }

  #head1 {
    background-color: #2c3e50 !important;
    height: 100% !important;
    margin-bottom: 10%;
    padding: 10px;
    margin-top: 0;
    margin-right: 0px;
    width: 100%;
  }

  #head2 {
    background-color: #008638 !important;
    /* background-color: #00cc99 !important; */
    height: 100% !important;
    margin-bottom: 10%;
    padding: 10px;
    margin-top: 0;
    margin-right: 0px;
    width: 100%;
  }

  #head3 {
    background-color: #ff4f74 !important;
    height: 100% !important;
    margin-bottom: 10%;
    padding: 10px;
    margin-top: 0;
    margin-right: 0px;
    width: 100%;
  }

  #head4 {
    background-color: #104aa0 !important;
    height: 100% !important;
    margin-bottom: 10%;
    padding: 10px;
    margin-top: 0;
    margin-right: 0px;
    width: 100%;
  }

  #head5 {
    background-color: #0f5132 !important;
    height: 100% !important;
    margin-bottom: 10%;
    padding: 10px;
    margin-top: 0;
    margin-right: 0px;
    width: 100%;
  }

  #head6 {
    background-color: #ff2c00 !important;
    height: 100% !important;
    margin-bottom: 10%;
    padding: 10px;
    margin-top: 0;
    margin-right: 0px;
    width: 100%;
  }

  .text {
    font-size: 11px;
    font-weight: 500;
    font-family: "poppins", sans-serif;
    position: relative;
    left: 0%;
  }
  .text h6 {
    font-size: 10px;
  }

  .content_word_h2 {
    font-size: 15px;
    color: #111111;
    left: 0%;
    position: relative;
    font-family: "poppins" "Noto Serif", serif;
  }

  .imgsection_right {
    background-color: #65d3ff !important;
    height: 95% !important;
    width: 100% !important;
    margin-left: 0%;
    margin-top: 0%;
    margin-bottom: 15%;
    cursor: pointer;
  }

  .content_word_right {
    float: right;
    position: relative;
    top: 10%;
    display: contents;
    /* font-size: 61px; */
    justify-content: center;
  }

  .content_word_right_h1 {
    font-size: 23px;
    font-family: "poppins" "Noto Serif", serif;
    font-size: 30px !important;
    position: relative;
    left: 0%;
    font-weight: 600;
  }

  .content_word_right_h2 {
    font-size: 15px;
    color: #111111;
    left: 0%;
    position: relative;
    font-family: "poppins" "Noto Serif", serif;
  }

  .imgsection_1 {
    background-color: hsla(164.67532467532467, 100%, 45.29%, 1) !important;
    height: 95% !important;
    width: 100% !important;
    margin-left: 0%;
    margin-top: 0%;
    margin-bottom: 15%;
    cursor: pointer;
  }

  .imgsection_right_1 {
    background-color: #fff965 !important;
    height: 95% !important;
    width: 100% !important;
    margin-left: 0%;
    margin-top: 0% !important;
    margin-bottom: 15%;
    cursor: pointer;
  }

  .imgsection_2 {
    background-color: hsl(292 86% 54% / 1) !important;
    height: 95% !important;
    width: 100% !important;
    margin-left: 0%;
    margin-top: 0%;
    margin-bottom: 15%;
    cursor: pointer;
  }

  .imgsection_right_2 {
    height: 95% !important;
    width: 100% !important;
    margin-left: 0%;
    margin-top: 0% !important;
    margin-bottom: 15%;
    cursor: pointer;
  }
}

@media only screen and (min-width: 431px) and (max-width: 770px) {
  .LandingPageContent {
    position: absolute;
    top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
  }
  /* .LandingPageContent p {
    font-weight: 500;
    opacity: 70%;
    font-size: 29px;
    font-weight: 700;
    font-family:"poppins" var(--defaultRegular);
    margin: 7rem 1.5rem;
    padding: 0 5rem;
    line-height: 1px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    margin-top: 100px;
}
.LandingPageContent h6 {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 48px;
  color: #fff;
  margin-top: 5rem;
  font-family:"poppins" var(--defaultRegular);
} */
  .backgroung {
    height: 60px !important;
  }

  .careerlandimage {
    /* background-image: url(../../Images/blogtab.png); */
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    height: 510px;
  }
}

@media only screen and (min-width: 761px) and (max-width: 1000px) {
  /* .LandingPageContent h6 {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 58px;
    color: #fff;
    margin-top: 10rem;
    font-family:"poppins" var(--defaultRegular);
} */
  .backgroung {
    height: 60px !important;
  }

  .careerlandimage {
    /* background-image: url(../../Images/blogtab.png); */
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    height: 510px;
  }

  .text {
    font-size: 24px !important;
  }
  .card_r {
    margin-top: 3rem;
  }
}

@media only screen and (min-width: 431px) and (max-width: 1439px) {
  .pht {
    width: 100% !important;
    height: 300px !important;
  }

  .text {
    font-size: 26px;
    font-weight: 500;
  }

  #head {
    height: 300px;
    padding: 0px;
    margin-left: 10px;
    width: 20%;
    cursor: pointer;
  }

  #head_r {
    height: 300px;
    padding: 0px;
    width: 20%;
    margin-left: 10px;
    cursor: pointer;
  }

  #head1 {
    background-color: #2c3e50;
    color: antiquewhite;
    height: 300px;
    margin-right: 10px;
    width: 28%;
    cursor: pointer;
  }

  #head2 {
    background-color: #008638 !important;
    color: antiquewhite;
    height: 300px;
    width: 28%;
    cursor: pointer;
  }
}

/*---CommentPost----------*/
.top {
  display: flex;
  justify-content: space-between !important;
}

.Button {
  border-bottom: 1px dashed #1f1f1f;
  padding: 3rem;
}

.commentform1 h6 {
  font-family: "poppins", sans-serif;
  font-size: 23px;
  font-weight: 900;
  margin-bottom: 2rem;
  text-decoration: underline;
}

.commentform1 {
  width: 90%;
}

.commentbox {
  background-image: linear-gradient(
    to right top,
    #51c9f1,
    #30b4fa,
    #4da7f3,
    #649aea,
    #778cde,
    #8189cb,
    #8687b9,
    #8885a7,
    #878496
  );
  box-shadow: 0 0px 16px 3px rgb(0 0 0 / 8%);
  padding: 15px;
  height: 600px;
  /* float: left; */
  width: 80%;
  margin-top: 90px;
  margin-left: 10%;
  display: flex;
  justify-content: center;
}

.commentbox2 {
  display: flex;
  justify-content: center;
  margin: 15px;
}

.commentform2 {
  padding-top: 20px;
}

.commentform2 input {
  width: 100%;
  min-height: 48px;
  background: #eee;
  box-shadow: none;
  outline: none;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
}

.modalheads.modal-header {
  background-color: white !important;
  background: white !important;
}

.btn {
  background: transparent;
  border: none;

  outline: none;
  color: grey;
}

.btn i:hover {
  cursor: pointer;
}

.commentbox1 {
  /* background-image: ; */
  box-shadow: 0px 0px 16px 3px rgb(0 0 0 / 8%);
  border-radius: 20px;
  /* padding: 10px; */
  height: 100%;
  /* float: left; */
  width: 80%;
  margin-top: 90px;
  margin-left: 10%;
  display: flex;
  justify-content: center;
}

.commentform {
  float: left;
  width: 100%;
  display: block;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px dashed #1f1f1f;
}

.commentform h3 {
  font-size: 20px;
  font-weight: 800;
  margin: 0;
  line-height: 1.5;
}

.commentform p {
  margin-bottom: 1rem;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

textarea {
  width: 100%;
  background: #eee;
  border: transparent;
}

.commentform input {
  width: 80%;
  min-height: 48px;
  background: #eee;
  box-shadow: none;
  outline: none;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
}

.comment-form-cookies-consent {
  display: table;
  width: 100%;
}

#checkbox {
  min-height: inherit;
}

.rc-anchor-light.rc-anchor-normal {
  border: 1px solid #d3d3d3;
}

@media only screen and (max-width: 320px) {
  .type h1 {
    font-family: "poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 40px;
    margin: 18rem 20rem 13rem 0rem;
    display: flex;
    color: #f6f6f6;
    justify-content: center;
    margin-top: -222px;
    width: 85%;
  }

  .textarea {
    resize: vertical;
    width: 100%;
  }

  .commentform input {
    width: 100% !important;
    min-height: 48px;
    background: #eee;
    box-shadow: none;
    outline: none;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
  }

  .commentbox {
    background-image: linear-gradient(
      to right top,
      #e1f6ee,
      #b5e9e5,
      #84dae8,
      #51c9f1,
      #30b4fa,
      #4da7f3,
      #649aea,
      #778cde,
      #8189cb,
      #8687b9,
      #8885a7,
      #878496
    );
    box-shadow: 0 0px 16px 3px rgb(0 0 0 / 8%);
    padding: 15px;
    height: 100%;
    float: left;
    width: 100%;
    margin-top: 90px;
    margin-left: 0px;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .commentform input {
    width: 100% !important;
    min-height: 48px;
    background: #eee;
    box-shadow: none;
    outline: none;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
  }

  .commentbox {
    background-image: linear-gradient(
      to right top,
      #e1f6ee,
      #b5e9e5,
      #84dae8,
      #51c9f1,
      #30b4fa,
      #4da7f3,
      #649aea,
      #778cde,
      #8189cb,
      #8687b9,
      #8885a7,
      #878496
    );
    box-shadow: 0 0px 16px 3px rgb(0 0 0 / 8%);
    padding: 15px;
    height: 100%;
    width: 100%;
    margin-top: 90px;
    margin-left: 0%;
    display: flex;
    justify-content: center;
  }

  .textarea {
    resize: vertical;
    width: 100%;
  }

  .commentform {
    float: left;
    width: 100%;
    display: block;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px dashed #1f1f1f;
    background-image: linear-gradient(
      to right top,
      #e1f6ee,
      #b5e9e5,
      #84dae8,
      #51c9f1,
      #30b4fa,
      #4da7f3,
      #649aea,
      #778cde,
      #8189cb,
      #8687b9,
      #8885a7,
      #878496
    );
  }
}

@media screen and (device-width: 1024px) {
  .backgroung {
    height: 55px;
  }

  /* .careerlandimage {
    background-image: url(../../Images/blog1000.png);
  } */
}

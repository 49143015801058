.main {
  position: absolute;
  top: 15%;
}
.Content {
  font-family: "poppins", sans-serif;
  font-weight: 900;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  /* width: 70%;
  text-align: center;
  
  font-style: normal;
  
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #ffffff;
  padding: 1rem;
   */
}

@keyframes showTopText {
  0% {
    transform: translate3d(0, 100%, 0);
  }

  40%,
  60% {
    transform: translate3d(0, 50%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes showBottomText {
  0% {
    transform: translate3d(0, -100%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes btnMain {
  0% {
    transform: translate3d(0, -100%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.animatedtitle {
  color: rgb(255 255 255);
  font-family: "poppins", sans-serif;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 60%;
  transform: translate(-50%, -50%);
  width: 80%;
  display: flex;
  justify-content: center;
  letter-spacing: 0.01em;
}
.MainHeader1 {
  position: absolute;
  background: transparent;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
}
.animatedtitle > div {
  height: 55%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  letter-spacing: 0.06em;
}

.animatedtitle > div div {
  font-size: 39px;
  padding: 2vmin 0;
  position: absolute;
}

.animatedtitle > div div span {
  display: block;
}

.animatedtitle > div.texttop div {
  animation: showBottomText 0.9s;
  animation-delay: 0.75s;
  animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -100%);
  text-transform: uppercase;
}

.animatedtitle > div.texttop div span:first-child {
  color: rgb(255 255 255);
  font-family: "poppins", sans-serif;
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: center;
}

.animatedtitle > div.textbottom {
  bottom: 0;
}

.animatedtitle > div.textbottom div {
  width: 100%;
  display: flex;
  margin-top: 20px;
  font-weight: 500;
  justify-content: center;
  animation: showBottomText 0.9s;
  animation-delay: 1.75s;
  animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -110%);
}

.btnMain > div.btn div {
  border-radius: 30em;
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  animation: showBottomText 0.9s;
  animation-delay: 2.75s;
  animation-fill-mode: forwards;
  top: 70%;
  transform: translate(0, 100%);
}

.btnMain {
  color: rgb(255 255 255);
  font-family: "poppins", sans-serif;
  left: 50%;
  position: absolute;
  top: 60%;
  transform: translate(-50%, -50%);
  width: 80%;
  display: flex;
  justify-content: center;
  letter-spacing: 0.01em;
}

.btnMain > div {
  height: 160px;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.image {
  display: block !important;
  width: 250px;
  margin: 0.5rem;
  height: 73px !important;
}

.image1 {
  display: block !important;
  /* width: 250px !important; */
  height: 60px;
}

.readMore {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: #ec2d01;
  padding: 4px 8px;
  color: white;

  text-decoration: none;
  border-radius: 20px;
  /* transform: rotate(180deg); */
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  justify-content: center;
  display: flex;
  height: 76px;
  align-items: center;
  width: 250px !important;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #0d1256;
}

.overlay img {
  width: 250px !important;
}

.container:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  width: 40%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.popup {
  position: fixed;
  background: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* display: none; */
}

.contentBox img {
  position: relative;
  max-width: 550px;
  z-index: 1;
}

#navhead {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "poppins", sans-serif;
  position: relative;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
}

#navhead::after {
  content: "";
  position: absolute;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  transform: scaleX(0);
  height: 3px;
  bottom: -5px;
 
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  transform-origin: bottom right;
  transition: transform 0.4s;
}

#navhead:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;

  /* background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%); */
}

#navhead.active::after {
  transform: scaleX(1);
  transform-origin: bottom left;

  /* background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%); */
}

.navbar-toggler {
  padding: 0 !important;
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
}

#icons {
  background-color: rgb(217, 221, 225) !important;
  margin-right: 0.5em;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #da2121 url(../../Images/close.png);
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center;
  cursor: pointer;
  z-index: 10;
}

#myModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.landimage {
  background-image: url(../../Images/LandingPage.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 750px;
  background-size: cover;
}

.MainheaderScroll {
  display: none;
}

.MainHeaderNavbar {
  background-image: linear-gradient(
    to right bottom,
    #001778,
    #002983,
    #00398d,
    #004895,
    #00579c,
    #0062a2,
    #006da6,
    #0077aa,
    #0080af,
    #0089b3,
    #0092b6,
    #009bb8
  );
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
}

.MainheaderLogo {
  display: flex;
  justify-content: space-around;
}

.MainheaderLogo1 {
  width: 300px;
  height: 70px;
  left: 130px;
  top: 41px;
}

.NavbarLogo {
  width: 200px;
  height: 50px;
}

.Contact_Us {
  font-size: 15px;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 100;
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  border-radius: 10px;
  margin-right: 1rem;
  width: 137px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.Contact_Us:hover {
  background: linear-gradient(
    90deg,
    rgba(1, 12, 84, 0.77) 0.63%,
    #089be2 53.76%,
    rgba(4, 46, 118, 0.87) 100%
  );
}

.ContactusMain {
  align-items: center;
  display: flex;
  justify-content: end;
}

.Hire_Us {
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  border-radius: 10px;
  margin-right: 1rem;
  width: 137px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.landingMainheader {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Icon {
  display: flex;
  justify-content: center;
}
.Icon img {
  width: 30px;
  height: 30px;
}

.LandingPageContent {
  width: 100%;
  height: 340px;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.LandingPageContent p {
  width: 70%;
  line-height: 59px;
  height: 400px;
  text-align: center;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #ffffff;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.MainDevelopCard {
  display: flex;
  flex-direction: row;
}

.Developmentcard {
  width: 24.5%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.2rem;
  cursor: pointer;
  background-color: #fdfdfd;
}

.Developmentcard:hover {
  width: 24.5%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.2rem;
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  cursor: pointer;
}

.Developmentcard:hover > pre {
  color: #ffffff;
}

.Developmentcard:hover > p {
  color: #ffffff;
}

.cardIcon {
  width: 80px;
  height: 80px;
}

.Developmentcard pre {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: bolder;
  font-size: 16px;
  text-align: center;
  white-space: pre-wrap;
  word-break: break-word;
  color: #042e76;
  margin: 1rem 0rem 0rem 0rem;
  height: 50px;
}

.card .Developmentcard p {
  font-size: 12px;
  height: 70px;
  margin: 0px !important;
  font-family: "poppins", sans-serif;
  font-weight: 900;
  height: 120px;
}

.SoftwareDevlopmentMainHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.SoftwareDevlopmentMainHeader span {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  color: #010c54;
  padding: 0.5rem;
}

.SoftwareDevlopmentMainhove {
  /* background-color: #089be2; */
  height: 6px;
  width: 70px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.SoftwareDevlopmentContent {
  width: 616px;
  height: 72px;
  left: calc(50% - 616px / 2);
  top: calc(50% - 72px / 2 - 1234px);
  font-family: "poppins", sans-serif;
  font-weight: 700;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #010c54;
}

/*--------------------*/
.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #fff;
  background-image: linear-gradient(
    to right top,
    #03045e,
    #00267a,
    #004494,
    #0061aa,
    #047ebe
  );
}

.l_footer {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.footerSub {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem;
  justify-content: center;
}

.MainheaderFooterLogo {
  width: 250px;
}

.Location {
  margin-right: 1rem;
}

h2 {
  font-weight: 400;
  font-size: 15px;
}

.h_box a {
  color: #999;
}

.Menu3 {
  text-decoration: none;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  margin-left: 1rem;
}

.Menu4 {
  text-decoration: none;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}

.Services {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 41px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
}

.FooterLocationContent {
  align-items: center;
  color: hsla(0, 0%, 100%, 0.9);
  display: flex;
  font-family: "poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
}

.h_box {
  column-count: 2;
  column-gap: 1.25em;
}

.b_footer {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.b_footerImg {
  flex: 0.4;
  display: flex;
  justify-content: center;
}

.b_footerImg img {
  margin-right: 1rem;
}

.b_footer p {
  margin: 0px !important;
}

.b_footerTxt {
  display: inline-block;
  flex: 1;
  display: flex;
  justify-content: center;
  color: rgba(255, 255, 255, 0.9);
}

.l_footer p {
  padding-right: 20%;
  color: #999;
}

.HeaderMenu {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #f27121;
}

.MenuMain {
  display: flex;
  flex-direction: column;
  /* width: 16%; */
  align-items: flex-start;
  margin-top: 4rem;
  text-align: initial;
}

.Menu1 :hover {
  color: #f27121;
}

.Menu1 {
  text-decoration: none;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}

.MenuMain1 {
  margin-top: 4rem;
  margin-left: -4rem !important;
}

.MenuMain2 {
  margin-top: 4rem;
}

.HeaderMenu2 {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #f27121;
  margin-left: 1rem;
}

.FooterLocationContent span {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-top: 1rem;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  padding-left: 1.5rem;
  width: 82%;
}

.Menu2 {
  text-decoration: none;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}
.zoho {
  text-decoration: none;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}
.zoho:hover {
  color: rgba(255, 255, 255, 0.9);
}

.FooterPhone {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
}

.FooterPhone span {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
}

.Contact_UsButton {
  width: 100% !important;
}

.FooterMain {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
}

.FooterMain span {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
}

.img_banner img {
  width: 50%;
}

.HeaderMenu1 {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #f27121;
}

.Contact_UsButtonHeader {
  justify-content: center;
  width: 70%;
}
.partnerShip {
  display: flex;
  justify-content: center;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 51px;
  /* line-height: 39px; */
  text-align: center;
  color: #ff6300;
  margin: 2rem 0;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .readMore {
    position: absolute;
    bottom: 2px;
    right: 2px;
    background-color: #ec2d01;
    color: white;
    padding: 1px 2px;
    text-decoration: none;
    font-size: 5px;
  }

  .Contact_UsButton {
    background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    border: navajowhite;
    font-family: "poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    padding: 0.5rem;
    width: 55% !important;
  }

  #navhead::after {
    content: "";
    position: absolute;
    width: 40%;
    align-items: center;

    display: flex;
    justify-content: center;
    transform: scaleX(0);
    height: 3px;
    bottom: 3px;
    left: 0%;
    background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
    transform-origin: bottom right;
    transition: transform 0.1s;
  }
  .animatedtitle > div {
    height: 58%;
    overflow: hidden;
    position: absolute;
    width: 100%;
    letter-spacing: 0.06em;
  }
  .animatedtitle > div.texttop div span:first-child {
    color: rgb(255 255 255);
    font-family: "poppins", sans-serif;
    font-size: 22px;
    text-align: center;
    /* font-weight: 500; */
  }
  .animatedtitle {
    color: rgb(255 255 255);
    font-family: "poppins", sans-serif;
    height: 70%;
    left: 50%;
    position: absolute;
    top: 60%;
    transform: translate(-50%, -50%);
    width: 80%;
    display: flex;
    justify-content: center;
    letter-spacing: 0.01em;
  }

  .animatedtitle > div div {
    font-size: 20px;
    text-align: center;
    padding: 2vmin 0;
    position: absolute;
  }
  .btnMain {
    color: rgb(255 255 255);
    font-family: "poppins", sans-serif;
    left: 50%;
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    width: 80%;
    display: flex;
    justify-content: center;
    letter-spacing: 0.01em;
  }
  .LandingPageContent1 {
    font-family: "poppins", sans-serif;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px;
    line-height: 29px !important;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 2rem;
    line-height: 20px;
  }

  .map {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .HeaderMenu1 {
    font-family: "poppins", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 27px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #f27121;
  }

  .MenuMain1 {
    margin-top: 0 !important;
    margin-left: 0 !important;
    width: 100% !important;
    align-items: center !important;
    margin-top: 1rem !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .MenuMain2 {
    margin-top: 0 !important;
    margin-left: 0 !important;
    width: 100% !important;
    align-items: center !important;
    margin-top: 1rem !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .HeaderMenu2 {
    margin-left: 0 !important;
  }

  .Menu3 {
    margin-top: 0 !important;
    margin-left: 0 !important;
  }

  .Menu4 {
    text-align: center;
  }

  .MainDevelopCard {
    display: flex;
    flex-direction: column;
  }

  .b_footerImg {
    margin-right: 0;
  }

  .FooterPhone {
    display: flex;
    flex-direction: column;
  }

  .FooterLocationContent span {
    margin-top: 0rem;
    justify-content: center;
    text-align: center;
    font-size: 18px;
  }

  .FooterMain {
    display: flex;
    flex-direction: column;
  }

  .FooterPhone span {
    padding: 0rem;
  }

  .FooterMain span {
    padding: 0rem;
  }

  .Phone {
    margin-right: 1rem;
  }

  .footerSub {
    display: flex;
    flex-direction: column;
  }

  .l_footer {
    width: 100%;
  }

  .b_footerTxt {
    display: inline-block;
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 2rem;
  }
  .MainHeader1 {
    /* position: fixed; */
    background: transparent;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1002;
  }
  .MenuMain {
    width: 100%;
    align-items: center;
    margin-top: 1rem;
  }

  .Developmentcard {
    width: 100%;
  }

  .Developmentcard:hover {
    width: 100%;
  }

  .SoftwareDevlopmentMainHeader span {
    display: flex;
    text-align: center;
  }

  .LandingPageContent p {
    width: 100%;
    font-size: 22px;
    margin: 5rem 0rem 0rem 0rem !important;
  }

  .ContactusMain {
    display: none;
  }

  .landingMainheader {
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .MainheaderFooterLogo {
    width: 220px;
    height: 65px;
    display: flex;
    margin: auto;
  }

  /* .MainHeaderNavbar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  } */

  .landimage {
    background-image: url(../../Images/LandingPage.png);
    background-repeat: no-repeat;
    width: 100%;
    height: 580px;
    background-size: cover;
    padding: 0;
  }

  /* .footer {
    padding: .5rem !important;
  } */
  .FooterLocationContent {
    display: flex;
    flex-direction: column;
  }

  .FooterLocationContent span {
    padding: 0rem;
  }

  .Location {
    margin-right: 0rem;
  }

  .b_footer {
    flex-direction: column;
  }

  .b_footer p {
    margin: 0px !important;
    font-size: 12px;
  }

  .Contact_UsButtonHeader {
    padding: 1.5rem;
    display: flex;
    justify-content: center !important;
    width: 80%;
  }

  .Contact_UsButton {
    width: 65% !important;
  }

  /* .back_1 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
    color: #fff;
    font-weight: bolder;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    transition: all 0.4s;
  } */

  .back_to_top {
    position: fixed;
    display: block;
    right: 15px;
    bottom: 100px;
    z-index: 9999;
  }
}

.Contact_UsButtonHeader {
  padding-top: 1rem;
  display: flex;
}

.UpSquaredScroll {
  width: 20%;
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  border-radius: 7px;
  margin-right: 1rem;
  border: none;
}

.back_1 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  font-size: 10px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  /* background: linear-gradient(90deg, #fffcfc 0%, #000000 103.31%); */
  background-color: #fff;
  color: #fff;
  font-weight: bolder;
  border-top-right-radius: 150px;
  border-top-left-radius: 150px;
  transition: all 0.4s;
}

.back_1 img {
  width: 140px;
  height: 60px;
}

.back_to_top {
  position: fixed;
  display: block;
  right: 15px;
  bottom: 15px;
  z-index: 9999;
}

.Contact_UsButton {
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  border-radius: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  border: navajowhite;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  padding: 0.5rem;
  width: 100% !important;
}

.Contact_UsTwo {
  margin-left: 10px;
  background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .readMore {
    position: absolute;
    bottom: 2px;
    right: 2px;
    background-color: #ec2d01;
    color: white;
    padding: 4px 6px;
    text-decoration: none;
    font-size: 10px;
  }

  #navhead::after {
    content: "";
    position: absolute;
    width: 80%;
    align-items: center;
    display: flex;
    justify-content: center;
    transform: scaleX(0);
    height: 3px;
    bottom: 0px;
    left: 10%;
    background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
    transform-origin: bottom right;
    transition: transform 0.4s;
  }
  .animatedtitle > div.texttop div span:first-child {
    color: rgb(255 255 255);
    font-family: "poppins", sans-serif;
    font-size: 22px;
    letter-spacing: 0.02em;
    /* font-weight: 500; */
    text-align: center;
  }
  .animatedtitle > div div {
    font-size: 25px;
    padding: 2vmin 0;
    position: absolute;
  }
  .animatedtitle > div {
    height: 45%;
    overflow: hidden;
    position: absolute;
    width: 100%;
    letter-spacing: 0.06em;
  }
  .Contact_UsButton {
    width: 100% !important;
    /* height: 43px !important; */
    font-size: 14px !important;
    margin-left: 1rem !important;
  }

  .UpSquaredScroll {
    height: 86%;
    width: 40%;
    background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
    border-radius: 7px;
    margin-right: 1rem;
    border: none;
  }

  .Contact_UsButtonHeader {
    justify-content: center;
    width: 100%;
    display: flex !important;
  }

  .landingMainheader {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Contact_Us {
    margin-right: 0 !important;
    width: 105px !important;
    margin-right: 3px !important;
  }

  .NavbarLogo {
    width: 130px;
    height: 40px;
  }

  .MainheaderLogo1 {
    width: 325px;
    height: 70px;
  }

  .MenuMain {
    margin-top: 4rem;
  }

  #rows > * {
    width: 25% !important;
  }

  .HeaderMenu2 {
    margin-left: 0.2rem;
  }

  .Menu3 {
    margin-left: 0.2rem;
  }

  .Menu2 {
    margin-left: 3rem;
    display: 0 !important;
    align-items: 0 !important;
    width: 85%;
  }
  .zoho {
    margin-left: 3rem;
    display: 0 !important;
    align-items: 0 !important;
    width: 85%;
  }

  .HeaderMenu {
    font-size: 20px !important;
  }

  .HeaderMenu2 {
    font-size: 20px !important;
  }

  .HeaderMenu1 {
    font-family: "poppins", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #f27121;
    margin-left: 3rem;
  }

  .FooterLocationContent span {
    font-size: 15px;
  }

  .LandingPageContent p {
    font-size: 30px;
    margin: 10rem 0rem 0rem 0rem !important;
  }

  .LandingPageContent1 {
    padding: 1.4rem;
    font-size: 22px;
  }

  .Contact_UsButtonHeader {
    margin-left: -1.5rem;
    width: 100%;
  }

  .image {
    display: block !important;
    width: 135px !important;
    height: 50px !important;
  }

  .image1 {
    display: block !important;
    width: 130px !important;
    height: 50px;
  }

  .overlay {
    width: 135px !important;
    height: 66px !important;
  }

  .overlay img {
    width: 130px !important;
    height: 50px !important;
  }

  .container:hover .overlay {
    opacity: 1;
  }

  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    width: 40%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  #navhead {
    /* padding: 10px !important; */
    font-size: 14px !important;
  }
}
.pos {
  position: absolute;
  bottom: 0%;
  left: 80%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 768px) {
  .MainheaderFooterLogo {
    width: 210px;
  }

  .LandingPageContent p {
    font-size: 36px;
  }

  div.textbottom div {
    margin-top: 0;
  }
  /* .LandingPageContent1 {
    padding: 1.4rem;
  } */
}

@media screen and (device-width: 1024px) {
  #navhead {
    padding: 10px !important;
    font-size: 18px !important;
  }
}

@media only screen and (min-width: 1444px) {
  .FooterLocationContent span {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 1540px) {
  /* .Menu4{
  font-size: 14px !important;
  }
  .Menu3{
    font-size: 14px !important;
  }
  .Menu1{
    font-size: 14px !important;
  }
  .Menu2{
    font-size: 14px !important;
  } */
}

.readMore:hover {
  background-color: #ec2c01c5;
  color: white;
}
.readMore:active {
  color: white;
}
#btnText {
  transform: rotate(180deg);
}
.read {
  position: absolute;
  right: 0;
  bottom: 0;
}

/* .MainDevelopCard {
  display: flex;
  flex-direction: row;
} */
/* #card{
  display: flex;
  flex-wrap: wrap;
} */
/* row > * {
  flex-shrink: 0;
  width: 25% !important;
  max-width: 100%;

} */

#card {
  background-color: aliceblue;
  padding: 1rem;
}

.Developmentcard {
  box-shadow: 0px 0 0.5px 0px;
  height: 97%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.2rem;
  text-align: initial;
  width: 100%;
  border-style: solid;
  border-width: 0px 0px 2px 0px;
  border-color: #ff6300;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

/* .Developmentcard:hover {
    background: linear-gradient(90deg, #e94057 0%, #f27121 103.31%);
  } */
.cardIcon {
  width: 80px;
  height: 80px;
}

/* .Developmentcard  pre {
  font-size: 18px !important;
  font-weight: 900 !important;
} */

.Developmentcard pre {
  color: #002b74;
  font-family: "poppins", sans-serif;
  font-weight: 700;
  line-height: 24px;
  margin: 1rem 0rem 0rem 0rem;
  height: 50px;
  font-size: 16px !important;
  text-align: center;
  white-space: pre-wrap;
  word-break: break-word;
  letter-spacing: 0.02em;
}

.Developmentcard p {
  font-size: 15px;
  height: 70px;
  height: 150px !important;
  font-family: "poppins", sans-serif;
  font-weight: 800;
  height: 100%;
  color: #010c54;
  padding: 10px;
  text-align: initial;
  letter-spacing: 0.05em;
}

.SoftwareDevlopmentMainHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  padding: 1rem;
  width: 100%;
}

.SoftwareDevlopmentMainHeader span {
  font-family: "poppins" "poppins" !important;
  font-weight: 900;
  font-size: 39px;
  line-height: 24px;
  color: #ff6300;
  padding: 0.5rem;
  margin: 2rem 0;
  letter-spacing: 0.02em;
}

.SoftwareDevlopmentMainhove {
  /* background-color: #089be2; */
  height: 8px;
  width: 70px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.SoftwareDevlopmentContent {
  width: 616px;
  height: 72px;
  left: calc(50% - 616px / 2);
  top: calc(50% - 72px / 2 - 1234px);
  font-family: "poppins", sans-serif;
  font-weight: 700;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #010c54;
}
@keyframes anim {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}
.Developmentcard:hover {
  /* height: 65%; */
  animation: anim 2s infinite;
  width: 100%;
  height: 97%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.2rem;
  text-align: initial;
  background-image: linear-gradient(1deg, #fa7f31 0%, #ed6621 100%);
  transform: scale(1.04);
}

.Developmentcard:hover > pre {
  color: #ffffff;
  /* font-size: 44px; */
}

.Developmentcard:hover > p {
  color: #ffffff;
  /* font-size: 14px;
  font-weight: 1000; */
}

.header {
  display: contents;
}

@media only screen and (min-width: 319px) and (max-width: 767px) {
  .MainDevelopCard {
    display: flex;
    flex-direction: column;
  }

  .Developmentcard {
    width: 100%;
    margin-right: 0px !important;
    height: 90%;
  }

  .Developmentcard:hover {
    width: 100%;
    height: 90%;
  }

  .SoftwareDevlopmentMainHeader span {
    display: flex;
    text-align: center;
    font-style: normal;
    font-weight: 900;
    font-size: 30px !important;
    line-height: 37px !important;
    padding: 0.5rem;
  }

  .SoftwareDevlopmentMainHeader {
    padding: 0;
  }

  .LandingPageContent p {
    width: 100%;
    font-size: 22px;
  }

  .ContactusMain {
    display: none;
  }

  .landingMainheader {
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .MainheaderLogo1 {
    width: 220px;
    height: 65px;
  }

  .LandingPageContent p {
    margin: 3rem 0rem 0rem 0rem !important;
  }

  .MainHeaderNavbar {
    background: linear-gradient(
      90deg,
      rgb(1, 12, 84) 0.63%,
      #089be2 53.76%,
      rgba(4, 46, 118, 0.973) 100%
    );
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Developmentcard p {
    height: 100%;
    width: 100%;
  }

  .SoftwareDevlopmentMainHeader span {
    font-size: 35px;
    line-height: 37px;
    text-align: center;
  }

  .Developmentcard {
    width: 100%;
    height: 97%;
    margin-bottom: 3rem;
  }

  .Developmentcard p {
    height: 100%;
    text-align: left;
    font-size: 14px;
  }

  .Developmentcard:hover {
    width: 100%;
    height: 90%;
    margin-bottom: 3rem;
  }
}

@media only screen and (min-width: 768px) {
  #cards {
    width: 50% !important;
  }
}

@media only screen and (min-width: 1024px) {
  #cards {
    width: 25% !important;
  }

  .Developmentcard:hover {
    width: 100%;
    height: 97%;
    margin-bottom: 0rem;
  }

  .Developmentcard {
    width: 100%;
    height: 97%;
    margin-bottom: 0rem;
  }
}
